import { Fragment, h } from "preact";
import layoutStyles from "../Layout.module.css";
import toyLayoutStyles from "./ToyLayout.module.css";
import RadioButtonRowText from "../../RadioButtonRow/RadioButtonRowText";
import Input from "../../Input";
import RadioButtonRowToyIcon from "../../RadioButtonRow/RadioButtonRowToyIcon";
import { useDispatch, useSelector } from "react-redux";
import * as toy from "../../../store/toySelectors";
import * as vishivka from "../../../store/vishivkaSelectors";
import {
  selectCurrentTovar,
  selectDefaultCategories,
} from "../../../store/leadReducer";
import { useUpdateTovar } from "../../utils";
import { setShowToyImage } from "../../../store/uiReducer";
import TopCategoriesList from "../../TopCategoriesList";
import ToyVisual from "./Visual";
import SectionHeader from "../../SectionHeader";
import RadioButtonRowToyMetric from "../../RadioButtonRow/RadioButtonRowToyMetric";

const Toy = () => {
  const tovar = useSelector(selectCurrentTovar);
  const categories = useSelector(selectDefaultCategories);
  const toyDefs = useSelector(toy.defaults);
  const vishivkaDefs = useSelector(vishivka.defaults);
  const isShowDopText = useSelector(toy.isShowDopText);
  const updateTovar = useUpdateTovar();
  const dispatch = useDispatch();

  return (
    <Fragment>
      <section className={layoutStyles.topContainer}>
        <section className={layoutStyles.categoriesSelect}>
          <TopCategoriesList
            selected={tovar.category}
            onChange={updateTovar("category")}
            options={categories}
          />
        </section>
        <section className={layoutStyles.visual}>
          <ToyVisual tovar={tovar} />
        </section>
        <section className={layoutStyles.fields}>
          <h2>Игрушка</h2>
          <RadioButtonRowToyIcon
            selected={tovar.toyType}
            onChange={(value) => {
              dispatch(setShowToyImage(true));
              updateTovar("toyType")(value);
            }}
            options={toyDefs.toyType}
          />
        </section>
      </section>

      <SectionHeader>Вышивка</SectionHeader>
      <section className={toyLayoutStyles.bottomContainer}>
        <section>
          <h2>Метрика</h2>
          <RadioButtonRowToyMetric
            selected={tovar.metricType}
            onChange={(value) => {
              dispatch(setShowToyImage(false));
              updateTovar("metricType")(value);
            }}
            options={toyDefs.metricType}
          />
        </section>

        <section>
          <h2>Текст вышивки</h2>
          <Input
            className={layoutStyles.element}
            labelText="Текст вышивки"
            placeholder={vishivkaDefs.placeholderText}
            type="textarea"
            value={tovar.text}
            onChange={updateTovar("text")}
          />
          {isShowDopText && (
            <Fragment>
              <h2>Вышивка на ушке (+350 р.)</h2>
              <Input
                labelText="Вышивка на ушке - введи текст (+350 р.)"
                placeholder="Введите текст"
                type="textarea"
                value={tovar.dopText}
                onChange={updateTovar("dopText")}
              />
            </Fragment>
          )}
        </section>
      </section>
    </Fragment>
  );
};

export default Toy;
