import { h } from "preact";
import Visual from "../Visual/Visual";
import { useSelector } from "react-redux";
import * as toy from "../../../store/toySelectors";
import { getAssetURL } from "../../../api";
import { useEffect, useState } from "preact/hooks";

const ToyVisual = ({ tovar, imageCallback = () => {} }) => {
  const img = useSelector(toy.getImageForVisual(tovar));

  const [imagesLoaded, addImageLoaded] = useState(0);
  useEffect(() => {
    if (imagesLoaded == 1) {
      imageCallback();
    }
  }, [imagesLoaded]);
  return (
    <Visual>
      {() => (
        <Fragment>
          <img
            src={getAssetURL(img)}
            onLoad={() => addImageLoaded(imagesLoaded + 1)}
          />
        </Fragment>
      )}
    </Visual>
  );
};

export default ToyVisual;
