import { Fragment, h } from "preact";
import { getAssetURL } from "../../api";
import styles from "./ModalSelectList.module.css";

const ModalSelectList = ({ options, selected, onItemClicked, imgColor }) => {
  const colorMaskId = `colorMask-${Math.random()}`;
  const imgStyle = {};
  if (imgColor) {
    imgStyle.filter = `url(#${colorMaskId})`;
  }
  return (
    <Fragment>
      {imgColor && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          width="0"
          height="0"
        >
          <defs>
            <filter id={colorMaskId}>
              <feFlood flood-color={imgColor} result="flood" />
              <feComposite
                in="SourceGraphic"
                in2="flood"
                operator="arithmetic"
                k1="1"
                k2="0"
                k3="0"
                k4="0"
              />
            </filter>
          </defs>
        </svg>
      )}
      <ul className={styles.list}>
        {options.map((opt) =>
          opt.value !== "" ? (
            <li
              key={opt.value}
              className={[
                styles.listItem,
                selected.value === opt.value ? styles.active : null,
              ].join(" ")}
              onClick={() => onItemClicked(opt)}
            >
              <div className={styles.imgContainer}>
                <img
                  style={opt.colored ? null : imgStyle}
                  src={getAssetURL(opt.value)}
                  alt={opt.title}
                />
              </div>
            </li>
          ) : null
        )}
      </ul>
    </Fragment>
  );
};

export default ModalSelectList;
