import { Fragment, h } from "preact";
import { setLeadId } from "../../store/apiActions";
import { selectIsLoading } from "../../store/uiReducer";
import { selectLeadId } from "../../store/leadReducer";
import Constructor from "../Constructor";
import FullScreenMessage from "../FullScreenMessage";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "preact/hooks";
import { pages } from "../URLManager";
import Spinner from "../Spinner";

const ConstructorRoute = ({ leadId }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const loadedLeadId = useSelector(selectLeadId);
  useEffect(() => {
    dispatch(setLeadId(leadId, pages.CONSTRUCTOR));
  }, [leadId, dispatch]);

  return isLoading || !loadedLeadId ? (
    <FullScreenMessage>
      <Spinner />
    </FullScreenMessage>
  ) : (
    <Constructor />
  );
};

export default ConstructorRoute;
