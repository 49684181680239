import { h } from "preact";
import styles from "./Visual.module.css";
import { useCallback, useRef } from "preact/hooks";

const resizeWrapper = (wrapper, outer) => {
  const wrapperW = wrapper.offsetWidth;
  const wrapperH = wrapper.offsetHeight;
  const outerW = outer.offsetWidth;
  const scale = outerW / wrapperW;

  wrapper.style.transform = `scale(${scale})`;
  wrapper.style.visibility = "visible";
  outer.style.height = `${wrapperH * scale}px`;
};

function useResizeObserver(callback) {
  return useCallback((node) => {
    if (node) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.target === node && entry.borderBoxSize?.length > 0) {
            callback(node, entry);
          }
        }
      });
      resizeObserver.observe(node);
    }
  }, []);
}

const Visual = ({ children, imgColor, textColor, className = "", style }) => {
  const imgColorMaskId = `colorMask-${imgColor}`;
  const imgColorFilter = `url(#${imgColorMaskId})`;
  const textColorMaskId = `colorMask-${textColor}`;
  const textColorFilter = `url(#${textColorMaskId})`;
  const lastOuterWidth = useRef(0);

  // Every time outer width changes, resize wrapper to fit
  // Every time wrapper dimentions change, resize outer height to same value (with scale)
  const outerRef = useResizeObserver((outer, entry) => {
    const newW = entry.borderBoxSize[0].inlineSize;
    if (lastOuterWidth.current && lastOuterWidth.current !== newW) {
      resizeWrapper(outer.firstChild, outer);
    }
    lastOuterWidth.current = newW;
  });
  const wrapperRef = useResizeObserver((wrapper) => {
    resizeWrapper(wrapper, wrapper.parentElement);
  });

  return (
    <div ref={outerRef}>
      <div ref={wrapperRef} className={styles.wrapper}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          width="0"
          height="0"
        >
          <defs>
            <filter id={imgColorMaskId}>
              <feFlood flood-color={imgColor} result="flood" />
              <feComposite
                in="SourceGraphic"
                in2="flood"
                operator="arithmetic"
                k1="1"
                k2="0"
                k3="0"
                k4="0"
              />
            </filter>
            <filter id={textColorMaskId}>
              <feFlood flood-color={textColor} result="flood" />
              <feComposite
                in="SourceGraphic"
                in2="flood"
                operator="arithmetic"
                k1="1"
                k2="0"
                k3="0"
                k4="0"
              />
            </filter>
          </defs>
        </svg>

        <div className={styles.container}>
          <div className={[styles.image, className].join(" ")} style={style}>
            {children({ imgColorFilter, textColorFilter })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Visual;
