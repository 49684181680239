const { chooseCorrectValue } = require("../utils");

const applyUpdates = (defaults, tovar, updates) => {
  const res = {};
  res.design = chooseCorrectValue(defaults.polotence.design, [
    updates.design,
    tovar.design,
  ]);
  res.orientation = chooseCorrectValue(defaults.polotence.orientation, [
    updates.orientation,
    tovar.orientation,
  ]);
  res.size = chooseCorrectValue(defaults.polotence.size, [
    updates.size,
    tovar.size,
  ]);
  res.color = chooseCorrectValue(defaults.polotence.color, [
    updates.color,
    tovar.color,
  ]);

  // Дизайн (уши) может быть только у большого полотенца
  if (res.size !== "140/70") {
    res.design = chooseCorrectValue(defaults.polotence.design, [""]);
  }
  return res;
};

const makeDefault = (defaults) => {
  return applyUpdates(defaults, {}, {});
};

module.exports = {
  applyUpdates,
  makeDefault,
};
