const getLeadId = (leadData) => leadData.id;
const getDefaultCategories = (defaults) => defaults.categories;
const getTovarNumber = (leadData) => leadData.tovars.length;
const getAllTovars = (leadData) => leadData.tovars;
const getAllTovarsInBasket = (leadData) =>
  getAllTovars(leadData).filter((t) => t.inBasket);
const getOptionByValue = (defaults, value) => {
  if (Array.isArray(defaults)) {
    return defaults.find((opt) => opt.value === value);
  } else {
    return defaults;
  }
};

const getOptionsByValues = (options, values) => {
  return Object.keys(values).reduce((acc, key) => {
    if (!options.hasOwnProperty(key)) return acc;
    acc[key] = getOptionByValue(options[key], values[key]);
    return acc;
  }, {});
};

module.exports = {
  getLeadId,
  getDefaultCategories,
  getTovarNumber,
  getAllTovars,
  getOptionByValue,
  getAllTovarsInBasket,
  getOptionsByValues,
};
