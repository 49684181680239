import { h } from "preact";
import styles from "./DeleteButton.module.css";

const DeleteButton = ({ onClick, className = "" }) => (
  <button
    onClick={(e) => onClick(e)}
    className={[styles.deleteButton, className].join(" ")}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      enable-background="new 0 0 40 40"
    >
      <line
        x1="15"
        y1="15"
        x2="25"
        y2="25"
        stroke="#fff"
        stroke-width="1"
        stroke-linecap="round"
        stroke-miterlimit="10"
      ></line>
      <line
        x1="25"
        y1="15"
        x2="15"
        y2="25"
        stroke="#fff"
        stroke-width="1"
        stroke-linecap="round"
        stroke-miterlimit="10"
      ></line>
      <path
        d="M20 1c10.45 0 19 8.55 19 19s-8.55 19-19 19-19-8.55-19-19 8.55-19 19-19z"
        class="progress"
        stroke="#fff"
        stroke-width="1"
        stroke-linecap="round"
        stroke-miterlimit="10"
        fill="none"
      ></path>
    </svg>
  </button>
);

export default DeleteButton;
