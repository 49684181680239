import { h } from "preact";
import styles from "./Modal.module.css";
import CloseButton from "../CloseButton";
import { useEffect } from "preact/hooks";

const Modal = ({ children, visible, close, header, style = {} }) => {
  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => (document.body.style.overflow = "auto");
  }, []);

  return (
    <div
      className={styles.backdrop}
      style={{ display: visible ? "flex" : "none" }}
      onClick={close}
    >
      <div
        className={styles.modal}
        style={style}
        onClick={(e) => e.stopPropagation()}
      >
        <header className={styles.header}>
          <CloseButton onClick={close} />
        </header>
        <h1>{header}</h1>
        {children}
      </div>
    </div>
  );
};

export const ButtonDrawer = ({ className = "", children }) => {
  return (
    <div className={[styles.buttonDrawer, className].join(" ")}>{children}</div>
  );
};

export default Modal;
