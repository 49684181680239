import { h } from "preact";
import styles from "./SectionHeader.module.css";

const SectionHeader = ({ children, className = "" }) => {
  return (
    <header className={[styles.container, className].join(" ")}>
      <div className={styles.frame}></div>
      <div className={styles.text}>{children}</div>
      <div className={styles.frame}></div>
    </header>
  );
};

export default SectionHeader;
