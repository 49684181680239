import { h } from "preact";
import styles from "./PromosList.module.css";
import { useDispatch, useSelector } from "react-redux";
import { selectAvailablePromos } from "../../../store/leadReducer";
import { applyPromo } from "../../../store/promos";

const PromosList = () => {
  const dispatch = useDispatch();
  const availablePromos = useSelector(selectAvailablePromos);

  if (availablePromos.length < 1) {
    return null;
  }

  return (
    <ul className={styles.promosList}>
      {availablePromos.map((promo) => (
        <li
          className={styles.promo}
          onClick={() => dispatch(applyPromo(promo))}
        >
          <img src={promo.data.img} />
          <span>
            <span className={styles.header}>{promo.data.header}</span>{" "}
            {promo.data.text}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default PromosList;
