import { configureStore } from "@reduxjs/toolkit";
import uiReducer, * as uiSlice from "./uiReducer";
import leadReducer, * as leadSlice from "./leadReducer";
import logger from "redux-logger";
import { saveStateToLocalstorageMiddleware } from "./middleware";

const getPreloadedState = () => ({
  ui: uiSlice.rehydrate(),
  lead: leadSlice.rehydrate(),
});

export default configureStore({
  reducer: {
    ui: uiReducer,
    lead: leadReducer,
  },
  preloadedState: getPreloadedState(),
  middleware: (getDefaultMiddleware) => {
    return process.env.NODE_ENV === "development"
      ? getDefaultMiddleware().concat(logger, saveStateToLocalstorageMiddleware)
      : getDefaultMiddleware().concat(saveStateToLocalstorageMiddleware);
  },
  devTools: process.env.NODE_ENV !== "production",
});
