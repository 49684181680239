const { getOptionByValue } = require("../selectors");
const { isShowDopText } = require("./selectors");
const { chooseCorrectValue } = require("../utils");

const applyUpdates = (defaults, tovar, updates) => {
  const res = {};
  res.toyType = chooseCorrectValue(defaults.toy.toyType, [
    updates.toyType,
    tovar.toyType,
  ]);
  res.metricType = chooseCorrectValue(defaults.toy.metricType, [
    updates.metricType,
    tovar.metricType,
  ]);
  if (isShowDopText(defaults, res)) {
    res.dopText = chooseCorrectValue(defaults.toy.dopText, [
      updates.dopText,
      tovar.dopText,
    ]);
  }
  return res;
};

const makeDefault = (defaults) => {
  return applyUpdates(defaults, {}, {});
};

module.exports = {
  applyUpdates,
  makeDefault,
};
