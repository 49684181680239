import { h } from "preact";
import styles from "./BasketButton.module.css";
import cartImg from "../../../assets/icons/shopping_cart.svg";

const BasketButton = ({ open, count }) => {
  return (
    <button className={styles["basket-button"]} onClick={open}>
      <div className={styles.countBadge}>
        <span>{count}</span>
      </div>
      <div className={styles.border}>
        <div className={styles.wrapper}>
          <img src={cartImg} />
        </div>
      </div>
    </button>
  );
};

export default BasketButton;
