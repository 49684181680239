import { h } from "preact";
import styles from "./RedirectModal.module.css";
import Button from "../Button";
import Modal, { ButtonDrawer } from "../Modal";
import { useEffect, useState } from "preact/hooks";

const SECONDS_TO_REDIRECT = 5;

const RedirectModal = ({ visible, close, goToNextPage, children }) => {
  const [count, setCount] = useState(SECONDS_TO_REDIRECT);

  useEffect(() => {
    const interval = setTimeout(() => {
      let newCount = count - 1;
      if (newCount <= 0) {
        goToNextPage();
      } else {
        setCount(newCount);
      }
    }, 1000);
    return () => clearTimeout(interval);
  }, [count, setCount, goToNextPage]);

  return (
    visible && (
      <Modal visible={visible} close={close}>
        <div className={styles.container}>
          <p className={styles.paragraph}>{children(count)}</p>
          <ButtonDrawer>
            <Button onClick={goToNextPage}>Перейти</Button>
          </ButtonDrawer>
        </div>
      </Modal>
    )
  );
};

export default RedirectModal;
