const { makeDefaults } = require("../../../scripts/defaults");

const defaults = makeDefaults();

defaults.halat.chestPic.push({
  value: "test value",
  title: "test image",
  colored: false,
  initials: true,
  img: "/assets/img/chestPics/1.jpg",
});
defaults.halat.childType.push({
  value: "test value",
  title: "test imgage",
  img: "/assets/img/halat/designerHalats/1.jpg",
  addPrice: 500,
});
defaults.polotence.design.push({
  title: "Полотенце зайка",
  value: "/assets/img/designPolotence/полотенце зайка.png",
});
defaults.polotence.design.push({
  title: "Полотенце котик",
  value: "/assets/img/designPolotence/полотенце котик.png",
});
defaults.toy.toyType.push({
  value: "Бегемот девочка",
  img: "/images/img/toy/img/Бегемот девочка/image.jpeg",
  icon: "/images/img/toy/img/Бегемот девочка/icon.png",
  price: 1990,
});
defaults.toy.toyType.push({
  value: "Бегемот мальчик",
  img: "/images/img/toy/img/Бегемот мальчик/image.jpeg",
  icon: "/images/img/toy/img/Бегемот мальчик/icon.png",
  price: 1990,
});
defaults.toy.metricType.push({
  title: "1",
  value: "/images/img/toy/metrics/1.jpg",
});
defaults.toy.metricType.push({
  title: "10",
  value: "/images/img/toy/metrics/10.jpg",
});
defaults.vishivka.upperPic.push({
  value: "test image",
  title: "test image",
  category: "Женские",
  colored: false,
  initials: false,
  img: "/assets/img/upperPics/1.jpg",
});
defaults.vishivka.upperPic.push({
  value: "test image 1",
  title: "test image 2",
  category: "Детские",
  colored: false,
  initials: false,
  img: "/assets/img/upperPics/1.jpg",
});
defaults.vishivka.lowerPic.push({
  value: "/assets/blank1.png",
  title: "test image 1",
});
defaults.vishivka.lowerPic.push({
  value: "/assets/blank2.png",
  title: "test image 2",
});
defaults.vishivka.lowerPic.push({
  value: "/assets/blank3.png",
  title: "test image 3",
});
defaults.vishivka.lowerPic.push({
  value: "/assets/blank4.png",
  title: "test image 4",
});
defaults.vishivka.lowerPic.push({
  value: "/assets/blank5.png",
  title: "test image 5",
});
defaults.vishivka.lowerPic.push({
  value: "/assets/blank6.png",
  title: "test image 6",
});
defaults.vishivka.lowerPic.push({
  value: "/assets/blank7.png",
  title: "test image 7",
});
defaults.vishivka.lowerPic.push({
  value: "/assets/blank8.png",
  title: "test image 8",
});
defaults.vishivka.lowerPic.push({
  value: "/assets/blank9.png",
  title: "test image 9",
});

module.exports = {
  defaults,
};
