const { chooseCorrectValue } = require("../utils");

const applyUpdates = (defaults, tovar, updates) => {
  const res = {};
  res.sex = chooseCorrectValue(defaults.tapki.sex, [updates.sex, tovar.sex]);
  res.fabric = chooseCorrectValue(defaults.tapki.fabric, [
    updates.fabric,
    tovar.fabric,
  ]);
  res.size = chooseCorrectValue(defaults.tapki.size, [
    updates.size,
    tovar.size,
  ]);
  res.color = chooseCorrectValue(defaults.tapki.color, [
    updates.color,
    tovar.color,
  ]);
  return res;
};

const makeDefault = (defaults) => {
  return applyUpdates(defaults, {}, {});
};

module.exports = {
  applyUpdates,
  makeDefault,
};
