import { h } from "preact";
import VishivkaVisual from "../Visual/VishivkaVisual";
import Visual from "../Visual/Visual";
import { useEffect, useState } from "preact/hooks";

// const img = require("../../../assets/img/tapki/tapki-white.png").default;
const imgs = {
  "#ffffff": require("../../../assets/img/tapki/tapki-white.png").default,
  "#191970": require("../../../assets/img/tapki/tapki-blue.png").default,
  "#000000": require("../../../assets/img/tapki/tapki-black.png").default,
};

const TapkiVisual = ({ tovar, imageCallback = () => {} }) => {
  const vishivkaStyleLeft = {};
  vishivkaStyleLeft.width = "35%";
  vishivkaStyleLeft.height = "45%";
  vishivkaStyleLeft.top = "46%";
  vishivkaStyleLeft.left = "9%";
  vishivkaStyleLeft["font-size"] = ".65em";

  const vishivkaStyleRight = {};
  vishivkaStyleRight.width = "35%";
  vishivkaStyleRight.height = "45%";
  vishivkaStyleRight.top = "46%";
  vishivkaStyleRight.left = "55%";
  vishivkaStyleRight["font-size"] = ".65em";

  const [imagesLoaded, addImageLoaded] = useState(0);
  useEffect(() => {
    if (imagesLoaded == 1) {
      imageCallback();
    }
  }, [imagesLoaded]);
  return (
    <Visual imgColor={tovar.color} textColor={tovar.textColor}>
      {({ imgColorFilter, textColorFilter }) => (
        <Fragment>
          <img
            src={imgs[tovar.color]}
            // style={{ filter: imgColorFilter }}
            onLoad={() => addImageLoaded(imagesLoaded + 1)}
          />
          <VishivkaVisual
            style={vishivkaStyleLeft}
            colorFilter={textColorFilter}
            tovar={tovar}
          />
          <VishivkaVisual
            style={vishivkaStyleRight}
            colorFilter={textColorFilter}
            tovar={tovar}
          />
        </Fragment>
      )}
    </Visual>
  );
};

export default TapkiVisual;
