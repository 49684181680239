import { h } from "preact";
import styles from "./RadioButtonRow.module.css";

const makeRadioButtonRow =
  (ItemComponent) =>
  ({ options, selected, onChange }) => {
    return (
      <ul className={styles.list}>
        {options.map((opt) => (
          <ItemComponent
            option={opt}
            isSelected={selected === opt.value}
            onClick={() => onChange(opt)}
          />
        ))}
      </ul>
    );
  };

export default makeRadioButtonRow;
