const vishivkaUpdates = require("./vishivka/updates");
const halatUpdates = require("./halat/updates");
const polotenceUpdates = require("./polotence/updates");
const toyUpdates = require("./toy/updates");
const podushkaUpdates = require("./podushka/updates");
const tapkiUpdates = require("./tapki/updates");
const halatSelectors = require("./halat/selectors");
const vishivkaSelectors = require("./vishivka/selectors");
const polotenceSelectors = require("./polotence/selectors");
const toySelectors = require("./toy/selectors");
const podushkaSelectors = require("./podushka/selectors");
const tapkiSelectors = require("./tapki/selectors");
const { chooseCorrectValue } = require("./utils");

const makeTovarForCategory = (defaults, category) => {
  switch (category) {
    case "HALAT":
      return halatUpdates.makeDefault(defaults);
    case "POLOTENCE":
      return polotenceUpdates.makeDefault(defaults);
    case "TOY":
      return toyUpdates.makeDefault(defaults);
    case "PODUSHKA":
      return podushkaUpdates.makeDefault(defaults);
    case "TAPKI":
      return tapkiUpdates.makeDefault(defaults);
  }
};

const makeDefaultTovar = (defaults, category, id, inBasket = false) => {
  return {
    id,
    category,
    inBasket,
    ...makeTovarForCategory(defaults, category),
    ...vishivkaUpdates.makeDefault(defaults),
  };
};

const applyCategoryUpdates = (defaults, tovar, updates) => {
  switch (tovar.category) {
    case "HALAT":
      return halatUpdates.applyUpdates(defaults, tovar, updates);
    case "POLOTENCE":
      return polotenceUpdates.applyUpdates(defaults, tovar, updates);
    case "TOY":
      return toyUpdates.applyUpdates(defaults, tovar, updates);
    case "PODUSHKA":
      return podushkaUpdates.applyUpdates(defaults, tovar, updates);
    case "TAPKI":
      return tapkiUpdates.applyUpdates(defaults, tovar, updates);
  }
};

const applyTovarUpdates = (defaults, tovar, updates = {}) => {
  if (updates.category && updates.category !== tovar.category) {
    // Если поменяли категорию, ресетим все поля на дефолтные
    return makeDefaultTovar(
      defaults,
      updates.category,
      tovar.id,
      tovar.inBasket
    );
  } else {
    return {
      id: tovar.id,
      category: tovar.category,
      inBasket: chooseCorrectValue(false, [updates.inBasket, tovar.inBasket]),
      ...applyCategoryUpdates(defaults, tovar, updates),
      ...vishivkaUpdates.applyUpdates(defaults, tovar, updates),
    };
  }
};

const getTovarDetails = (defaults, tovar) => {
  switch (tovar.category) {
    case "HALAT":
      const res = halatSelectors.getDetails(defaults, tovar);
      return {
        ...res,
        price: res.price + vishivkaSelectors.calculatePrice(defaults, tovar),
      };
    case "POLOTENCE":
      return polotenceSelectors.getDetails(defaults, tovar);
    case "TOY":
      return toySelectors.getDetails(defaults, tovar);
    case "PODUSHKA":
      return podushkaSelectors.getDetails(defaults, tovar);
    case "TAPKI":
      return tapkiSelectors.getDetails(defaults, tovar);
  }
};

const getTovarTelegramDescription = (defaults, tovar) => {
  switch (tovar.category) {
    case "HALAT":
      return halatSelectors.getTelegramDescription(defaults, tovar);
    case "POLOTENCE":
      return polotenceSelectors.getTelegramDescription(defaults, tovar);
    case "TAPKI":
      return tapkiSelectors.getTelegramDescription(defaults, tovar);
  }
};

module.exports = {
  applyTovarUpdates,
  makeDefaultTovar,
  getTovarDetails,
  getTovarTelegramDescription,
};
