import { Fragment, h } from "preact";
import styles from "./Constructor.module.css";
import ContactForm from "../ContactForm";
import ConstructorSwitch from "./ConstructorSwitch";
import { useSelector } from "react-redux";
import {
  selectCurrentTovar,
  selectIsLeadEditable,
} from "../../store/leadReducer";
import DisabledScreen from "../DisabledScreen";
import Basket from "./Basket";
import SectionHeader from "../SectionHeader";

const Constructor = () => {
  const isLeadEditable = useSelector(selectIsLeadEditable);
  const tovar = useSelector(selectCurrentTovar);

  return (
    <Fragment>
      {!isLeadEditable && <DisabledScreen />}
      <section className={styles.constructor}>
        <ConstructorSwitch category={tovar.category} />
      </section>
      <section className={styles.contacts}>
        <SectionHeader className={styles.header}>
          Оформление заказа
        </SectionHeader>
        <ContactForm />
      </section>
      <Basket />
    </Fragment>
  );
};

export default Constructor;
