import { h } from "preact";
import styles from "./SelectBox.module.css";
import ArrowIcon from "./ArrowIcon";
import { useState } from "preact/hooks";

const SelectBox = ({ options, selected, onChange, className = "" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerClasses = [styles.container, className];
  if (isOpen) containerClasses.push(styles.open);
  const selectedOption = options.find((o) => o.value === selected);

  return (
    <div
      tabIndex={0}
      className={containerClasses.join(" ")}
      onBlur={() => setTimeout(() => setIsOpen(false), 100)}
    >
      <div
        className={styles.control}
        style={{ "font-family": selected }}
        onClick={() => setIsOpen(true)}
      >
        <span>{selectedOption.title}</span>
        <ArrowIcon direction="down" />
      </div>
      <div className={styles.menu}>
        <div className={styles.top} style={{ "font-family": selected }}>
          <div className={styles.inner} onClick={() => setIsOpen(false)}>
            <span>{selectedOption.title}</span>
            <ArrowIcon direction="up" />
          </div>
        </div>
        <ul>
          {options.map((opt) => (
            <li
              style={{ "font-family": opt.value }}
              key={opt.value}
              onClick={() => {
                setIsOpen(false);
                onChange(opt);
              }}
            >
              {opt.title}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SelectBox;
