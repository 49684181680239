import { h } from "preact";
import makeRadioButtonRow from ".";
import styles from "./RadioButtonRowText.module.css";
import checkmark from "../../assets/icons/checkmark.svg";

const ListItemText = ({ option, isSelected, onClick }) => {
  const classes = [styles.listItem];
  if (isSelected) classes.push(styles.active);
  return (
    <li key={option.value} className={classes.join(" ")} onClick={onClick}>
      <div className={styles.innerItem}>
        <span className={styles.checkMark}>
          <img src={checkmark} />
        </span>
        <span>{option.title}</span>
      </div>
    </li>
  );
};

const RadioButtonRowText = makeRadioButtonRow(ListItemText);

export default RadioButtonRowText;
