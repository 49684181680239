import { h } from "preact";
import styles from "./Input.module.css";

const Input = ({
  name = "",
  type = "text",
  value = "",
  placeholder = "",
  disabled = false,
  onChange,
  className = "",
}) => {
  return type === "textarea" ? (
    <textarea
      className={[styles.field, className].join(" ")}
      placeholder={placeholder}
      disabled={disabled}
      name={name}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  ) : (
    <input
      className={[styles.field, className].join(" ")}
      type={type}
      placeholder={placeholder}
      disabled={disabled}
      name={name}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export default Input;
