import { Fragment, h } from "preact";
import layoutStyles from "../Layout.module.css";
import RadioButtonRowText from "../../RadioButtonRow/RadioButtonRowText";
import RadioButtonRowColor from "../../RadioButtonRow/RadioButtonRowColor";
import ModalSelect from "../../ModalSelect";
import { useSelector } from "react-redux";
import { getOptionByValue, useUpdateTovar } from "../../utils";
import * as polotence from "../../../store/polotenceSelectors";
import * as vishivka from "../../../store/vishivkaSelectors";
import {
  selectCurrentTovar,
  selectDefaultCategories,
} from "../../../store/leadReducer";
import TopCategoriesList from "../../TopCategoriesList";
import PolotenceVisual from "./Visual";
import ModalSelectUpperPic from "../../ModalSelectUpperPic";
import SelectBox from "../../SelectBox";
import Input from "../../Input";
import FontSizeSlider from "../../FontSizeSlider";
import SectionHeader from "../../SectionHeader";

const Polotence = () => {
  const tovar = useSelector(selectCurrentTovar);
  const categories = useSelector(selectDefaultCategories);
  const polotenceDefs = useSelector(polotence.defaults);
  const updateTovar = useUpdateTovar();
  const vishivkaDefs = useSelector(vishivka.defaults);
  const upperPicOptionsByCategory = useSelector(
    vishivka.upperPicOptionsByCategory
  );

  const designOption = getOptionByValue(polotenceDefs.design, tovar.design);
  const designButtonLabel =
    designOption.value === "" ? "Выбрать" : designOption.title;

  return (
    <Fragment>
      <section className={layoutStyles.topContainer}>
        <section className={layoutStyles.categoriesSelect}>
          <TopCategoriesList
            selected={tovar.category}
            onChange={updateTovar("category")}
            options={categories}
          />
        </section>
        <section className={layoutStyles.visual}>
          <PolotenceVisual tovar={tovar} />
        </section>
        <section className={layoutStyles.fields}>
          <h2>Размер</h2>
          <RadioButtonRowText
            selected={tovar.size}
            onChange={updateTovar("size")}
            options={polotenceDefs.size}
          />

          <h2>Цвет ткани</h2>
          <RadioButtonRowColor
            selected={tovar.color}
            onChange={updateTovar("color")}
            options={polotenceDefs.color}
          />
        </section>
      </section>

      <SectionHeader>Вышивка</SectionHeader>
      <section className={layoutStyles.bottomContainer}>
        <section>
          <h2>Расположение вышивки</h2>
          <RadioButtonRowText
            selected={tovar.orientation}
            onChange={updateTovar("orientation")}
            options={polotenceDefs.orientation}
          />

          <h2>Цвет вышивки</h2>
          <RadioButtonRowColor
            selected={tovar.textColor}
            onChange={updateTovar("textColor")}
            options={vishivkaDefs.textColor}
          />
        </section>

        <section>
          <h2>Текст вышивки</h2>
          <Input
            className={layoutStyles.element}
            labelText="Моё полотенце"
            type="textarea"
            placeholder={vishivkaDefs.placeholderText}
            value={tovar.text}
            onChange={updateTovar("text")}
          />

          <SelectBox
            title="Выберите шрифт текста"
            selected={tovar.font}
            onChange={updateTovar("font")}
            options={vishivkaDefs.font}
            className={layoutStyles.element}
          />
        </section>

        <section>
          <h2>Размер шрифта</h2>
          <FontSizeSlider
            className={layoutStyles.element}
            value={tovar.fontSize}
            onChange={updateTovar("fontSize")}
          />

          <h2>Рисунок</h2>
          <div className={layoutStyles.picSelectBlock}>
            <ModalSelectUpperPic
              emptyOption={vishivkaDefs.upperPic[0]}
              selected={getOptionByValue(vishivkaDefs.upperPic, tovar.upperPic)}
              onChange={updateTovar("upperPic")}
              optionsByCategory={upperPicOptionsByCategory}
              labelText="Верхний"
              imgColor={tovar.textColor}
              header="Выберите верхний рисунок"
            />
            <ModalSelect
              emptyOption={vishivkaDefs.lowerPic[0]}
              selected={getOptionByValue(vishivkaDefs.lowerPic, tovar.lowerPic)}
              onChange={updateTovar("lowerPic")}
              options={vishivkaDefs.lowerPic}
              labelText="Нижний"
              imgColor={tovar.textColor}
              header="Выберите нижний рисунок"
            />
          </div>
        </section>
      </section>
    </Fragment>
  );
};

export default Polotence;
