import { h } from "preact";
import styles from "./FontSizeSlider.module.css";

const FontSizeSlider = ({ value, onChange, className = "" }) => {
  return (
    <div className={[styles.container, className].join(" ")}>
      <input
        className={[styles.fontSize].join(" ")}
        type="range"
        name="fontSize"
        min="1"
        max="5"
        step="0.1"
        value={value}
        onChange={(e) => onChange(parseFloat(e.target.value))}
      />
    </div>
  );
};

export default FontSizeSlider;
