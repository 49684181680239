import { h } from "preact";
import styles from "./DisabledScreen.module.css";

const DisabledScreen = () => {
  return (
    <div className={styles.disabledScreen}>
      <div className={styles.disabledMessage}>
        Этот заказ уже оплачен, его нельзя редактировать
      </div>
    </div>
  );
};

export default DisabledScreen;
