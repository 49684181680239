import { Fragment, h } from "preact";
import { useState } from "preact/hooks";
import Button from "../Button";
import Modal, { ButtonDrawer } from "../Modal";
import ModalSelectList from "../ModalSelectList";
import { scrollToTop } from "../utils";
import styles from "./ModalSelect.module.css";

const ModalSelect = ({
  options,
  selected,
  onChange,
  emptyOption,
  labelText,
  imgColor,
  header,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const onOptSelected = (opt) => {
    setModalOpen(false);
    onChange(opt);
    scrollToTop();
  };

  return (
    <Fragment>
      <Button
        primary
        onClick={() => setModalOpen(true)}
        className={styles.opener}
      >
        {labelText}
      </Button>
      {modalOpen && (
        <Modal
          visible={modalOpen}
          close={() => setModalOpen(false)}
          header={header}
        >
          <ModalSelectList
            imgColor={imgColor}
            options={options}
            selected={selected}
            onItemClicked={(opt) => onOptSelected(opt)}
          />
          <ButtonDrawer>
            <Button onClick={() => onOptSelected(emptyOption)}>
              {emptyOption.actionTitle}
            </Button>
          </ButtonDrawer>
        </Modal>
      )}
    </Fragment>
  );
};

export default ModalSelect;
