import { h } from "preact";
import { useSelector } from "react-redux";
import styles from "./Visual.module.css";
import * as vishivka from "../../../store/vishivkaSelectors";
import { getAssetURL } from "../../../api";

const VishivkaVisual = ({ style = {}, className = "", colorFilter, tovar }) => {
  const upperPic = useSelector(vishivka.getUpperPic(tovar));
  const lowerPic = useSelector(vishivka.getLowerPic(tovar));
  const vishivkaDefs = useSelector(vishivka.getDefaultsForTovar(tovar));

  const text = tovar.isTextSetByUser
    ? tovar.text
    : vishivkaDefs.placeholderText;

  return (
    <div style={style} className={[styles.vishivka, className].join(" ")}>
      {upperPic && upperPic.value && (
        <img
          className={styles.upper}
          src={getAssetURL(upperPic.value)}
          style={upperPic.colored ? null : { filter: colorFilter }}
        />
      )}
      {text && (
        <div
          style={{
            "font-family": tovar.font,
            "font-size": `${tovar.fontSize}em`,
            color: tovar.textColor,
          }}
          className={styles.text}
        >
          {text}
        </div>
      )}
      {lowerPic && lowerPic.value && (
        <img
          className={styles.lower}
          src={getAssetURL(lowerPic.value)}
          style={lowerPic.colored ? null : { filter: colorFilter }}
        />
      )}
    </div>
  );
};

export default VishivkaVisual;
