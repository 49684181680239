import { h } from "preact";
import Halat from "./Halat";
import Podushka from "./Podushka";
import Polotence from "./Polotence";
import Tapki from "./Tapki";
import Toy from "./Toy";

const ConstructorSwitch = ({ category }) => {
  switch (category) {
    case "HALAT":
      return <Halat />;
    case "POLOTENCE":
      return <Polotence />;
    case "TOY":
      return <Toy />;
    case "PODUSHKA":
      return <Podushka />;
    case "TAPKI":
      return <Tapki />;
  }
};

export default ConstructorSwitch;
