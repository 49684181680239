import { h } from "preact";
import styles from "./FullScreenMessage.module.css";

const FullScreenMessage = ({ children }) => {
  return (
    <div className={styles.loadScreen}>
      <div className={styles.inner}>{children}</div>
    </div>
  );
};

export default FullScreenMessage;
