const { getOptionByValue } = require("./selectors");

const chooseCorrectValue = (defaults, values = []) => {
  if (!Array.isArray(defaults)) {
    return chooseCorrectSimpleValue(defaults, values);
  }

  for (let val of values) {
    if (defaults.find((v) => v.value === val)) {
      return val;
    }
  }
  return defaults[0].value;
};

const chooseCorrectSimpleValue = (defaults, values = []) => {
  for (let val of values) {
    if (val !== undefined) {
      return val;
    }
  }
  return defaults;
};

const calculatePriceFromFields = (options, tovar) => {
  return Object.keys(options)
    .map((key) => getOptionByValue(options[key], tovar[key]))
    .filter((o) => o !== undefined)
    .reduce((acc, val) => {
      if (val && val.addPrice) {
        acc += val.addPrice;
      }
      return acc;
    }, 0);
};

const calcPredoplata = (total, percent) => {
  return Math.ceil((total * percent) / 10000) * 100;
};

const trimPhone = (phone) => {
  return `+${phone.replace(/\D/g, "")}`;
};

module.exports = {
  chooseCorrectValue,
  chooseCorrectSimpleValue,
  calculatePriceFromFields,
  calcPredoplata,
  trimPhone,
};
