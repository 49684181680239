const { getAllTovarsInBasket } = require("./selectors");

const tovarSuitsConditions = (conditions) => (tovar) => {
  for (const key of Object.keys(conditions)) {
    if (tovar[key] !== conditions[key]) {
      return false;
    }
  }
  return true;
};

const checkConditions = (conditions, tovars) => {
  if (Array.isArray(conditions)) {
    for (let i = 1; i < conditions.length; i++) {
      const suitingTovar = tovars.find(tovarSuitsConditions(conditions[i]));
      if (suitingTovar) {
        return suitingTovar;
      }
    }
    return null;
  } else {
    return tovars.find(tovarSuitsConditions(conditions));
  }
};

const getRestTovars = (tovars, usedTovars) =>
  tovars.filter((t) => !usedTovars.includes(t.id));

const checkPromo = (promo, tovars) => {
  const usedTovars = [];

  // check if we have all tovars needed
  for (const conditions of promo.tovars) {
    const suitingTovar = checkConditions(
      conditions,
      getRestTovars(tovars, usedTovars)
    );
    if (!suitingTovar) {
      return null;
    }
    usedTovars.push(suitingTovar.id);
  }

  return {
    data: promo,
    usedTovars,
  };
};

const getAvailablePromos = (promos, lead) => {
  // Выбираем все товары, которые используются в активинованных промо
  const usedTovars = lead.activePromos.reduce((res, p) => {
    res = res.concat(p.usedTovars);
    res = res.concat(p.giftTovars);
    return res;
  }, []);

  // Выбираем все товары в корзине, которые не используются в активированных промо
  let tovars = getRestTovars(getAllTovarsInBasket(lead), usedTovars);

  // Проверяем каждую промо, подходит ли она к корзине
  const res = promos
    .map((p) => checkPromo(p, tovars))
    .filter((item) => item !== null);

  return res;
};

const getPromoById = (allPromos, id) => allPromos.find((p) => p.id === id);

const checkIfActivePromoCorrect = (allPromos, promo, lead) => {
  const promoData = getPromoById(allPromos, promo.id);
  // Выбираем все товары, задействованные в промо
  const tovars = lead.tovars.filter((t) => promo.usedTovars.includes(t.id));

  // Проверяем, подходит ли все еще промо
  const res = checkPromo(promoData, tovars);
  if (!res) {
    return false;
  }

  // Проверяем, не удалили ли из корзины подарки
  if (Array.isArray(promoData.reward.gift)) {
    for (let i = 0; i < promoData.reward.gift.length; i++) {
      const conditions = promoData.reward.gift[i];
      const gift = lead.tovars.find((t) => t.id === promo.giftTovars[i]);
      if (!gift || !tovarSuitsConditions(conditions)(gift)) {
        return false;
      }
    }
  }

  return true;
};

const getDiscounts = (allPromos, activePromos) => {
  return activePromos.reduce((res, p) => {
    const discounts = {};
    const promo = getPromoById(allPromos, p.id);

    // Собираем все скидки на товары в корзине
    if (Array.isArray(promo.reward.discount)) {
      for (let i = 0; i < promo.reward.discount.length; i++) {
        if (promo.reward.discount[i] > 0) {
          discounts[p.usedTovars[i]] = promo.reward.discount[i];
        }
      }
    }

    // Добавляем скидку в 100% для всех подарков
    for (const tovarId of p.giftTovars) {
      discounts[tovarId] = 1;
    }

    return {
      ...res,
      ...discounts,
    };
  }, {});
};

const chooseCorrectPromos = (allPromos, lead, updates) => {
  const newActivePromos = [];
  // Updates override active promos in the lead
  const activePromos = updates || lead.activePromos;
  for (let i = 0; i < activePromos.length; i++) {
    if (checkIfActivePromoCorrect(allPromos, activePromos[i], lead)) {
      newActivePromos.push(activePromos[i]);
    }
  }
  return newActivePromos;
};

module.exports = {
  checkConditions,
  getRestTovars,
  getAvailablePromos,
  checkIfActivePromoCorrect,
  getDiscounts,
  chooseCorrectPromos,
};
