import { h } from "preact";
import makeRadioButtonRow from ".";
import styles from "./RadioButtonRowColor.module.css";

const ListItemColor = ({ option, isSelected, onClick }) => {
  const classes = [styles.listItem];
  if (isSelected) classes.push(styles.active);
  return (
    <li key={option.apiValue} className={classes.join(" ")} onClick={onClick}>
      <span className={styles.innerItem}>
        <span style={{ "background-color": option.value }}></span>
      </span>
    </li>
  );
};

const RadioButtonRowColor = makeRadioButtonRow(ListItemColor);

export default RadioButtonRowColor;
