import { h } from "preact";
import styles from "./ContactForm.module.css";
import Input from "../Input";
import Button from "../Button";
import PolicyLink from "../PolicyLink";
import PhoneInput from "../Input/PhoneInput";
import { useDispatch, useSelector } from "react-redux";
import {
  selectComment,
  selectContact,
  selectCurrentTovar,
  selectTovarPrice,
} from "../../store/leadReducer";
import { useUpdateLead, scrollToTop } from "../utils";
import { addNewTovar, updateTovar } from "../../store/apiActions";
import CompleteOrderButton from "../CompleteOrderButton";
import { setShowBasket } from "../../store/uiReducer";
import Modal, { ButtonDrawer } from "../Modal";
import { useState } from "preact/hooks";

const ContactForm = () => {
  const tovar = useSelector(selectCurrentTovar);
  const price = useSelector(selectTovarPrice(tovar));
  const contact = useSelector(selectContact);
  const comment = useSelector(selectComment);
  const updateLead = useUpdateLead();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const makeNewTovar = () => {
    dispatch(addNewTovar());
    setShowModal(false);
    scrollToTop();
  };
  const openBasket = () => {
    dispatch(setShowBasket(true));
    setShowModal(false);
  };
  const addToBasket = () => {
    dispatch(updateTovar({ inBasket: true }));
    setShowModal(true);
  };
  return (
    <div className={styles.container}>
      <div className={styles.price}>
        <span className={styles.fullText}>Итоговая стоимость:</span>
        <span className={styles.smallText}>Итого:</span>
        <span className={styles.number}>{price} руб.</span>
      </div>
      <div className={styles.formContainer}>
        <Input
          className={styles.first}
          placeholder="ФИО"
          name="name"
          value={contact.name}
          onChange={updateLead("name")}
        />
        <PhoneInput
          className={styles.second}
          name="phone"
          value={contact.phone}
          onChange={updateLead("phone")}
        />
        <Input
          className={styles.fourth}
          placeholder="Комментарий по заказу..."
          type="textarea"
          name="comment"
          value={comment}
          onChange={updateLead("comment")}
        />

        {tovar.inBasket ? (
          <Button onClick={makeNewTovar} className={styles.leftButton}>
            Создать еще один товар
          </Button>
        ) : (
          <Button primary onClick={addToBasket} className={styles.leftButton}>
            Добавить в корзину
          </Button>
        )}
        <CompleteOrderButton className={styles.rightButton} />
        <PolicyLink className={styles.policyLink} />
      </div>
      <Modal
        visible={showModal}
        close={() => setShowModal(false)}
        header="Товар добавлен в корзину"
        style={{ "max-width": "40em" }}
      >
        <ButtonDrawer>
          <Button primary onClick={makeNewTovar}>
            Создать еще один товар
          </Button>
          <Button onClick={openBasket}>Перейти в корзину</Button>
        </ButtonDrawer>
      </Modal>
    </div>
  );
};

export default ContactForm;
