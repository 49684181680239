import { h } from "preact";
import Spinner from "../Spinner";
import styles from "./Button.module.css";

const Button = ({
  className,
  children,
  primary,
  isLoading = false,
  ...props
}) => {
  const classes = [className, styles.button];
  if (primary) {
    classes.push(styles.primary);
  }
  return (
    <button className={classes.join(" ")} {...props}>
      <span className={styles.inner}>
        {isLoading ? <Spinner className={styles.spinner} /> : children}
      </span>
    </button>
  );
};

export default Button;
