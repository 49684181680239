const { getOptionsByValues } = require("../selectors");
const { calculatePriceFromFields } = require("../utils");

const getOptions = (defaults, tovar) => {
  const placeholderText = defaults.vishivka.placeholderText?.find(
    (t) => t.category === tovar.category
  )?.value;
  return {
    isTextSetByUser: defaults.vishivka.isTextSetByUser,
    text: defaults.vishivka.text,
    initialsText: defaults.vishivka.initialsText,
    textColor: defaults.vishivka.textColor,
    lowerPic: defaults.vishivka.lowerPic,
    font: defaults.vishivka.font,
    fontSize: defaults.vishivka.fontSize,
    upperPic: defaults.vishivka.upperPic,
    placeholderText,
  };
};

const calculatePrice = (defaults, tovar) => {
  const options = getOptions(defaults, tovar);
  return calculatePriceFromFields(options, tovar);
};

const makeImageLink = (title, path, baseURL) => {
  const imgURL = new URL(path, baseURL);
  return `<a href="${imgURL.href}">${title}</a>`;
};

const getVishivkaTelegramDescription = (defaults, tovar, baseURL) => {
  const options = getOptions(defaults, tovar);
  const values = getOptionsByValues(options, tovar);
  const upperPic = tovar.upperPic
    ? makeImageLink(
        `${values.upperPic.title} (${values.upperPic.colored ? "цветная" : "в цвет вышивки"})`,
        tovar.upperPic === "USER_UPLOAD" ? tovar.imgURL : values.upperPic.value,
        baseURL
      )
    : "нет";
  const lowerPic = tovar.lowerPic
    ? makeImageLink(values.lowerPic.title, values.lowerPic.value, baseURL)
    : "нет";

  const lines = [
    `Вышивка`,
    `Текст: ${tovar.isTextSetByUser ? tovar.text : options.placeholderText}`,
    `Шрифт: ${values.font.title}`,
    `Размер: ${tovar.fontSize}`,
    `Цвет: ${values.textColor.title}`,
    `Верхний рисунок: ${upperPic}`,
    `Нижний рисунок: ${lowerPic}`,
  ];
  return lines.join("\n  ");
};

module.exports = {
  getOptions,
  calculatePrice,
  getVishivkaTelegramDescription,
};
