module.exports.makeDefaults = () => ({
  active: true,
  categories: [
    { title: "Халат", value: "HALAT" },
    { title: "Полотенце", value: "POLOTENCE" },
    { title: "Тапочки", value: "TAPKI" },
  ],
  halat: {
    sex: [
      { title: "Мужской", value: "MALE", addPrice: 3500 },
      { title: "Женский", value: "FEMALE", addPrice: 3500 },
    ],
    fabric: [
      { title: "Махровый", value: "MAHR" },
      { title: "Вафля", value: "WAFLYA" },
    ],
    size: {
      adult: [
        { value: "44", title: "44" },
        { value: "46", title: "46" },
        { value: "48", title: "48" },
        { value: "50", title: "50" },
        { value: "52", title: "52" },
        { value: "54", title: "54" },
        { value: "56", title: "56" },
        { value: "58", title: "58" },
        { value: "60", title: "60" },
        { value: "62", title: "62" },
        { value: "64", title: "64" },
      ],
      child: [
        { value: "80\\86", title: "80\\86" },
        { value: "92\\98", title: "92\\98" },
        { value: "104\\110", title: "104\\110" },
        { value: "116\\122", title: "116\\122" },
        { value: "128\\134", title: "128\\134" },
        { value: "140\\146", title: "140\\146" },
      ],
    },
    color: {
      adult: [
        { title: "белый", value: "#FFFFFF" },
        { title: "темно-синий", value: "#191970" },
        { title: "черный", value: "#000000" },
      ],
      child: [
        { title: "белый", value: "#FFFFFF" },
        { title: "темно-синий", value: "#191970" },
        { title: "черный", value: "#000000" },
      ],
    },
    vishivkaPos: [
      { title: "на спине", value: "BACK" },
      { title: "на груди", value: "FRONT" },
      { title: "на спине и на груди", value: "BACK_AND_FRONT" },
    ],
    chestPic: [
      {
        value: "",
        title: "Без рисунка на груди",
        img: null,
        tag: "EMPTY",
        actionTitle: "Удалить рисунок",
      },
    ],
    childType: [
      {
        value: "",
        title: "Без дизайна",
        tag: "EMPTY",
        actionTitle: "Удалить дизайн",
      },
    ],
    hood: [
      { title: "Без капюшона", value: "WITHOUT" },
      { title: "С капюшоном", value: "WITH" },
    ],
  },
  polotence: {
    orientation: [
      { title: "По длине", value: "LENGTH" },
      { title: "По ширине", value: "WIDTH" },
    ],
    color: [
      { title: "белый", value: "#FFFFFF" },
      { title: "темно-синий", value: "#191970" },
      { title: "черный", value: "#000000" },
    ],
    size: [
      { value: "140/70", title: "140/70", addPrice: 1700 },
      { value: "90/50", title: "90/50", addPrice: 1700 },
    ],
    design: [
      {
        value: "",
        title: "Без дизайна",
        tag: "EMPTY",
        actionTitle: "Удалить дизайн",
      },
    ],
  },
  toy: {
    toyType: [],
    metricType: [],
    dopText: "",
    dopTextAddPrice: 350,
  },
  podushka: {
    fabric: [
      { title: "Плюшевая", value: "PLUSH", addPrice: 1490 },
      { title: "Эко-кожа", value: "KOZHA", addPrice: 1490 },
    ],
    color: [
      { title: "темно-синий", value: "#191970" },
      { title: "синий", value: "#0000CD" },
      { title: "черный", value: "#000000" },
      { title: "белый", value: "#ffffff" },
    ],
  },
  tapki: {
    sex: [
      { title: "Мужские", value: "MALE", addPrice: 1500 },
      { title: "Женские", value: "FEMALE", addPrice: 1500 },
    ],
    fabric: [
      { title: "Махровые", value: "MAHR" },
      { title: "Вафля", value: "WAFLYA" },
    ],
    size: [
      { value: "36", title: "36" },
      { value: "37", title: "37" },
      { value: "38", title: "38" },
      { value: "39", title: "39" },
      { value: "40", title: "40" },
      { value: "41", title: "41" },
      { value: "42", title: "42" },
      { value: "43", title: "43" },
      { value: "44", title: "44" },
      { value: "45", title: "45" },
      { value: "46", title: "46" },
    ],
    color: [
      { title: "белый", value: "#ffffff" },
      { title: "темно-синий", value: "#191970" },
      { title: "черный", value: "#000000" },
    ],
  },
  vishivka: {
    isTextSetByUser: false, // true means that the user has changed this text
    text: "",
    placeholderText: [
      { category: "HALAT", value: "Мой любимый халат" },
      { category: "POLOTENCE", value: "Моё любимое полотенце" },
      {
        category: "TOY",
        value: "Напишите имя, дату, время, вес и рост ребенка",
      },
      { category: "PODUSHKA", value: "Моя любимая подушка" },
      { category: "TAPKI", value: "Мои любимые тапочки" },
    ],
    initialsText: "",
    textColor: [
      { title: "золото", value: "#DAA520" },
      { title: "серебро", value: "#D3D3D3" },
      { title: "синий", value: "#000080" },
      { title: "белый", value: "#ffffff" },
      { title: "черный", value: "#000000" },
      { title: "зеленый", value: "#2E8B57" },
      { title: "малиновый", value: "#B22222" },
      { title: "фиолетовый", value: "#800080" },
      { title: "розовый", value: "#FF00FF" },
    ],
    upperPic: [
      {
        value: "",
        title: "Без верхнего рисунка",
        tag: "EMPTY",
        actionTitle: "Удалить рисунок",
        img: null,
      },
      {
        value: "USER_UPLOAD",
        title: "Загруженный рисунок",
        tag: "USER_UPLOAD",
        img: null,
        colored: true,
      },
    ],
    lowerPic: [
      {
        value: "",
        title: "Без нижнего рисунка",
        tag: "EMPTY",
        actionTitle: "Удалить рисунок",
        img: null,
      },
    ],
    font: [
      { title: "Nautilus", value: "Nautilus" },
      { title: "Isadora", value: "Isadora" },
      { title: "Amour 1", value: "Amour1" },
      { title: "Amour 2", value: "Amour2" },
      { title: "Georgia", value: "Georgia" },
      { title: "Boyarsky", value: "Boyarsky" },
      { title: "Rosamunda", value: "Rosamunda" },
      { title: "Skazka", value: "Skazka" },
      { title: "Monotipe Corsiva", value: "monotipe_corsiva" },
      { title: "Copyist", value: "Copyist" },
      { title: "Ribbon", value: "Ribbon" },
      { title: "Goticrus", value: "Goticrus" },
      { title: "Majestic", value: "Majestic" },
      { title: "Heuristica", value: "Heuristica" },
    ],
    fontSize: 2.5,
  },
  deliveryType: [
    { title: "Почта (от 350 р.)", value: "Почта" },
    { title: "СДЕК (от 350 р.)", value: "СДЕК" },
    { title: "Самовывоз (бесплатно)", value: "Самовывоз" },
  ],
  predoplata: [
    { title: "Без предоплаты", value: "NONE" },
    { title: "Предоплата %NUMBER% р.", value: "PARTIAL", percent: 25 },
    { title: "Полная предоплата", value: "FULL" },
  ],
  comment: "",
  promos: [],
});
