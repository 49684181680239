import { h } from "preact";
import VishivkaVisual from "../Visual/VishivkaVisual";
import Visual from "../Visual/Visual";
import { useEffect, useState } from "preact/hooks";

const img = require("../../../assets/img/podushka/pod_white.png").default;

const PodushkaVisual = ({ tovar, imageCallback = () => {} }) => {
  const vishivkaStyle = {};
  vishivkaStyle.width = "60%";
  vishivkaStyle.height = "60%";
  vishivkaStyle.top = "20%";
  vishivkaStyle.left = "20%";

  const [imagesLoaded, addImageLoaded] = useState(0);
  useEffect(() => {
    if (imagesLoaded == 1) {
      imageCallback();
    }
  }, [imagesLoaded]);
  return (
    <Visual imgColor={tovar.color} textColor={tovar.textColor}>
      {({ imgColorFilter, textColorFilter }) => (
        <Fragment>
          <img
            src={img}
            style={{ filter: imgColorFilter }}
            onLoad={() => addImageLoaded(imagesLoaded + 1)}
          />
          <VishivkaVisual
            style={vishivkaStyle}
            colorFilter={textColorFilter}
            tovar={tovar}
          />
        </Fragment>
      )}
    </Visual>
  );
};

export default PodushkaVisual;
