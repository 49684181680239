import { selectCurrentTovar, selectDefaults } from "./leadReducer";
import { selectShowToyImage } from "./uiReducer";
const selectors = require("../../../back/src/common/selectors");
const commonToy = require("../../../back/src/common/toy/selectors");

const sortMetrics = (metrics) => {
  const res = [...metrics];
  res.sort((a, b) => {
    return parseInt(a.title, 10) - parseInt(b.title, 10);
  });
  return res;
};

export const defaults = (state) => {
  const opts = commonToy.getOptions(
    selectDefaults(state),
    selectCurrentTovar(state)
  );
  return {
    ...opts,
    metricType: sortMetrics(opts.metricType),
  };
};

export const isShowDopText = (state) => {
  return commonToy.isShowDopText(
    selectDefaults(state),
    selectCurrentTovar(state)
  );
};

export const getImageForVisual = (tovar) => (state) => {
  const opts = commonToy.getOptions(selectDefaults(state), tovar);
  if (selectShowToyImage(state)) {
    return selectors.getOptionByValue(opts.toyType, tovar.toyType).img;
  } else {
    return selectors.getOptionByValue(opts.metricType, tovar.metricType).value;
  }
};
