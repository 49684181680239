const { getOptionByValue, getOptionsByValues } = require("../selectors");
const { calculatePriceFromFields } = require("../utils");

const getOptions = (defaults, tovar) => {
  return {
    sex: defaults.tapki.sex,
    fabric: defaults.tapki.fabric,
    size: defaults.tapki.size,
    color: defaults.tapki.color,
  };
};

const calculatePrice = (defaults, tovar) => {
  const options = getOptions(defaults, tovar);
  return calculatePriceFromFields(options, tovar);
};

const getDetails = (defaults, tovar) => {
  const sex = getOptionByValue(defaults.tapki.sex, tovar.sex);
  return {
    title: `Тапочки`,
    subTitle: `Размер: ${tovar.size}`,
    price: calculatePrice(defaults, tovar),
  };
};

const getTelegramDescription = (defaults, tovar) => {
  const options = getOptions(defaults, tovar);
  const values = getOptionsByValues(options, tovar);

  const lines = [
    `Тапочки`,
    `Ткань: ${values.fabric.title}`,
    `Размер: ${values.size.title}`,
    `Цвет: ${values.color.title}`,
  ];
  return lines.join("\n  ");
};

module.exports = {
  getOptions,
  calculatePrice,
  getDetails,
  getTelegramDescription,
};
