const halatSelectors = require("./halat/selectors");
const vishivkaSelectors = require("./vishivka/selectors");
const polotenceSelectors = require("./polotence/selectors");
const toySelectors = require("./toy/selectors");
const podushkaSelectors = require("./podushka/selectors");
const tapkiSelectors = require("./tapki/selectors");
const { getDiscounts } = require("./promos");
const { getAllTovarsInBasket } = require("./selectors");
const { getTovarDetails } = require("./tovar");
const { calcPredoplata } = require("./utils");

const getTovarPrice = (defaults, tovar) => {
  switch (tovar.category) {
    case "HALAT":
      return (
        halatSelectors.calculatePrice(defaults, tovar) +
        vishivkaSelectors.calculatePrice(defaults, tovar)
      );
    case "POLOTENCE":
      return polotenceSelectors.calculatePrice(defaults, tovar);
    case "TOY":
      return toySelectors.calculatePrice(defaults, tovar);
    case "PODUSHKA":
      return podushkaSelectors.calculatePrice(defaults, tovar);
    case "TAPKI":
      return tapkiSelectors.calculatePrice(defaults, tovar);
  }
};

const getPriceWithDiscount = (price, discount) => {
  if (!discount || discount <= 0) {
    return price;
  }
  if (discount <= 1) {
    return Math.ceil(price * (1 - discount));
  }
  if (discount > 1) {
    return Math.ceil(price - discount);
  }
};

const getLeadTotalPrice = (defaults, lead) => {
  const discounts = getDiscounts(defaults.promos, lead.activePromos);
  return getAllTovarsInBasket(lead).reduce(
    (res, t) => {
      const details = getTovarDetails(defaults, t);
      res.total += details.price;
      res.totalWithDiscount += getPriceWithDiscount(
        details.price,
        discounts[t.id]
      );
      return res;
    },
    {
      total: 0,
      totalWithDiscount: 0,
    }
  );
};

const getLeadPredoplata = (lead, leadTotalPrice, predoplataOptions) => {
  if (lead.predoplata === "FULL") {
    return leadTotalPrice;
  } else if (lead.predoplata === "PARTIAL") {
    const option = predoplataOptions.find((o) => o.value === "PARTIAL");
    return calcPredoplata(leadTotalPrice, option.percent);
  } else {
    return 0;
  }
};

module.exports = {
  getTovarPrice,
  getPriceWithDiscount,
  getLeadTotalPrice,
  getLeadPredoplata,
};
