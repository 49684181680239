import { h } from "preact";
import styles from "./Basket.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectShowBasket,
  setCurrentTovar,
  setShowBasket,
} from "../../../store/uiReducer";
import {
  selectBasketTotal,
  selectTovarsInBasket,
} from "../../../store/leadReducer";
import { deleteTovar } from "../../../store/apiActions";
import Basket from "./Basket";

const BasketContainer = () => {
  const dispatch = useDispatch();
  const showBasket = useSelector(selectShowBasket);
  const open = () => dispatch(setShowBasket(true));
  const close = () => dispatch(setShowBasket(false));
  const { total, totalWithDiscount } = useSelector(selectBasketTotal);
  const tovars = useSelector(selectTovarsInBasket);

  const onEdit = (tovar) => {
    close();
    dispatch(setCurrentTovar(tovar));
  };
  const onDelete = (tovar) => {
    dispatch(deleteTovar(tovar));
  };

  return (
    <Basket
      open={open}
      close={close}
      showBasket={showBasket}
      tovars={tovars}
      onEdit={onEdit}
      onDelete={onDelete}
      total={total}
      totalWithDiscount={totalWithDiscount}
    />
  );
};

export default BasketContainer;
