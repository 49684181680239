import { createSlice } from "@reduxjs/toolkit";
import { fetchLead, loadLeadData } from "./leadReducer";

const rem = (arr, item) => arr.filter((r) => r !== item);

const getInitialState = () => {
  return {
    error: null,
    status: "idle", // idle | loading | complete | failed
    pendingRequests: [],
    showToyImage: true,
    showBasket: false,
    currentTovar: null,
    showDemoWarning: true,
  };
};

export const uiSlice = createSlice({
  name: "ui",
  initialState: getInitialState(),
  reducers: {
    addError: (state, action) => {
      state.error = action.payload;
    },
    clearErrors: (state) => {
      state.error = null;
    },
    setShowToyImage: (state, action) => {
      state.showToyImage = Boolean(action.payload);
    },
    setShowBasket: (state, action) => {
      state.showBasket = Boolean(action.payload);
    },
    setCurrentTovar: (state, action) => {
      state.currentTovar = action.payload.id;
    },
    closeDemoWarning: (state) => {
      state.showDemoWarning = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLead.pending, (state, action) => {
      state.status = "loading";
      state.pendingRequests.push(action.payload);
    });
    builder.addCase(fetchLead.fulfilled, (state, action) => {
      state.pendingRequests = rem(state.pendingRequests, action.payload.reqId);
      if (state.pendingRequests.length === 0) {
        state.status = "complete";
      }
    });
    builder.addCase(fetchLead.rejected, (state, action) => {
      state.pendingRequests = rem(state.pendingRequests, action.payload.reqId);
      state.status = "failed";
      state.error = action.payload.data;
    });
    builder.addCase(loadLeadData, (state, action) => {
      const tovars = action.payload.tovars;
      state.currentTovar = tovars[tovars.length - 1].id;
    });
  },
});

export const {
  addError,
  clearErrors,
  setShowToyImage,
  setShowBasket,
  setCurrentTovar,
  closeDemoWarning,
} = uiSlice.actions;

export default uiSlice.reducer;

export const selectError = (state) => state.ui.error;
export const selectStatus = (state) => state.ui.status;
export const selectIsLoading = (state) => state.ui.status !== "complete";
export const selectShowToyImage = (state) => state.ui.showToyImage;
export const selectShowBasket = (state) => state.ui.showBasket;
export const selectCurrentTovarId = (state) => state.ui.currentTovar;
export const selectShowDemoWarning = (state) => state.ui.showDemoWarning;

export const hydrate = (state) => {
  localStorage.setItem("demoWarningClosed", !selectShowDemoWarning(state));
};

export const rehydrate = () => {
  const demoWarningClosed = localStorage.getItem("demoWarningClosed");
  const ui = { ...getInitialState() };
  if (demoWarningClosed === "true") ui.showDemoWarning = false;
  return ui;
};
