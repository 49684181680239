import { Fragment, h } from "preact";
import { useState } from "preact/hooks";
import Button from "../Button";
import Modal, { ButtonDrawer } from "../Modal";
import ModalSelectList from "../ModalSelectList";
import UploadImageButton from "./UploadImageButton";
import styles from "./ModalSelectUpperPic.module.css";
import TopCategoriesList from "../TopCategoriesList";
import { scrollToTop } from "../utils";

const ModalSelectUpperPic = ({
  optionsByCategory,
  selected,
  onChange,
  emptyOption,
  labelText,
  imgColor,
  header,
}) => {
  const categories = Object.keys(optionsByCategory).map((cat) => ({
    value: cat,
    title: cat,
  }));
  const [modalOpen, setModalOpen] = useState(false);
  const [catSelected, setCatSelected] = useState(categories[0]);

  const onOptSelected = (opt) => {
    setModalOpen(false);
    onChange(opt);
    scrollToTop();
  };

  return (
    <Fragment>
      <Button
        primary
        onClick={() => setModalOpen(true)}
        className={styles.opener}
      >
        {labelText}
      </Button>
      {modalOpen && (
        <Modal
          visible={modalOpen}
          close={() => setModalOpen(false)}
          header={header}
        >
          <TopCategoriesList
            selected={catSelected.value}
            onChange={(cat) => setCatSelected(cat)}
            options={categories}
          />
          <ModalSelectList
            imgColor={imgColor}
            options={optionsByCategory[catSelected.value]}
            selected={selected}
            onItemClicked={(opt) => onOptSelected(opt)}
          />
          <ButtonDrawer>
            <UploadImageButton onChange={onOptSelected} />
            <Button onClick={() => onOptSelected(emptyOption)}>
              {emptyOption.actionTitle}
            </Button>
          </ButtonDrawer>
        </Modal>
      )}
    </Fragment>
  );
};

export default ModalSelectUpperPic;
