import { Fragment, h } from "preact";
import { useSelector } from "react-redux";
import VishivkaVisual from "../Visual/VishivkaVisual";
import Visual from "../Visual/Visual";
import styles from "./Visual.module.css";
import * as halat from "../../../store/halatSelectors";
import { getAssetURL } from "../../../api";
import { useEffect, useState } from "preact/hooks";

const chestBGImage =
  require("../../../assets/img/halat/halat-chest.png").default;

const HalatVisual = ({ tovar, imageCallback = () => {} }) => {
  const img = useSelector(halat.getImage(tovar));
  const hoodImg = useSelector(halat.getHoodImage(tovar));
  const chestImg = useSelector(halat.getChestImage(tovar));
  const showFrontVishivka = useSelector(halat.showFrontVishivka(tovar));
  const showBackVishivka = useSelector(halat.showBackVishivka(tovar));
  const [imagesLoaded, addImageLoaded] = useState(0);

  useEffect(() => {
    if ((imagesLoaded == 1 && !hoodImg) || (imagesLoaded == 2 && hoodImg)) {
      imageCallback();
    }
  }, [imagesLoaded, hoodImg]);

  const vishivkaStyle = {};
  const imgStyle = {};
  const hoodStyle = {};
  if (tovar.sex === "CHILD") {
    vishivkaStyle.width = "40%";
    vishivkaStyle.height = "34%";
    vishivkaStyle.top = "45%";
    vishivkaStyle.left = "29%";
    vishivkaStyle["font-size"] = "0.6em";
  } else {
    vishivkaStyle.width = "60%";
    vishivkaStyle.height = "70%";
    vishivkaStyle.top = "19%";
    vishivkaStyle.left = "19%";
    if (hoodImg) {
      hoodStyle.top = "-13%";
      hoodStyle.left = "-1%";
      imgStyle["margin-top"] = "70px"; // Это должно быть в пикселях, т.к. процент считается от высоты картинки, а не капюшона
    }
  }
  return (
    <Visual imgColor={tovar.color} textColor={tovar.textColor} style={imgStyle}>
      {({ imgColorFilter, textColorFilter }) => (
        <Fragment>
          {showFrontVishivka && (
            <div className={styles.chestImg}>
              <img
                className={styles.chestBG}
                src={chestBGImage}
                style={{ filter: imgColorFilter }}
              />
              {chestImg && (
                <img
                  className={styles.chestPic}
                  src={getAssetURL(chestImg)}
                  style={{ filter: textColorFilter }}
                />
              )}
            </div>
          )}
          <img
            src={img}
            style={{ filter: imgColorFilter }}
            onLoad={() => addImageLoaded(imagesLoaded + 1)}
          />
          {hoodImg && (
            <div style={hoodStyle} className={styles.hood}>
              <img
                src={hoodImg}
                style={{ filter: imgColorFilter }}
                onLoad={() => addImageLoaded(imagesLoaded + 1)}
              />
            </div>
          )}
          {showBackVishivka && (
            <VishivkaVisual
              style={vishivkaStyle}
              colorFilter={textColorFilter}
              tovar={tovar}
            />
          )}
        </Fragment>
      )}
    </Visual>
  );
};

export default HalatVisual;
