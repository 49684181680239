import { h } from "preact";
import styles from "./Basket.module.css";
import Modal from "../../Modal";
import TovarsList from "./TovarsList";
import Totals from "./Totals";
import CompleteOrderButton from "../../CompleteOrderButton";
import PromosList from "./PromosList";
import PolicyLink from "../../PolicyLink";
import BasketButton from "./BasketButton";

const Basket = ({
  open,
  close,
  showBasket,
  onEdit,
  onDelete,
  total,
  totalWithDiscount,
  tovars,
}) => {
  if (showBasket) {
    return (
      <Modal
        visible={showBasket}
        close={close}
        header="Корзина"
        style={{ "max-width": "73rem" }}
      >
        {tovars.length > 0 ? (
          <Fragment>
            <div className={styles.disclaimer}>
              Обратите внимание: сделанный вами макет — схематичный. Вышивка
              будет красочной, яркой, ровной и максимально крупной.
            </div>
            <TovarsList tovars={tovars} onEdit={onEdit} onDelete={onDelete} />
            <PromosList />
            <Totals total={total} totalWithDiscount={totalWithDiscount} />
            <div className={styles.divider}></div>
            <div className={styles.buttonDrawer}>
              <CompleteOrderButton primary className={styles.submitButton} />
              <PolicyLink />
            </div>
          </Fragment>
        ) : (
          <h2 className={styles.emptyHeader}>Корзина пуста!</h2>
        )}
      </Modal>
    );
  } else {
    return <BasketButton open={open} count={tovars.length} />;
  }
};

export default Basket;
