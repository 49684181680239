const { chooseCorrectValue } = require("./utils");

module.exports.applyContactUpdates = (contact, updates) => {
  return {
    name: chooseCorrectValue("", [updates.name, contact.name]),
    phone: chooseCorrectValue("", [updates.phone, contact.phone]),
    address: chooseCorrectValue("", [updates.address, contact.address]),
  };
};

module.exports.makeDefaultContact = (contact) => {
  return this.applyContactUpdates(contact, {});
};
