import { h } from "preact";
import HalatVisual from "./Halat/Visual";
import PodushkaVisual from "./Podushka/Visual";
import PolotenceVisual from "./Polotence/Visual";
import TapkiVisual from "./Tapki/Visual";
import ToyVisual from "./Toy/Visual";

const VisualSwitch = ({ ...props }) => {
  switch (props.tovar.category) {
    case "HALAT":
      return <HalatVisual {...props} />;
    case "POLOTENCE":
      return <PolotenceVisual {...props} />;
    case "TOY":
      return <ToyVisual {...props} />;
    case "PODUSHKA":
      return <PodushkaVisual {...props} />;
    case "TAPKI":
      return <TapkiVisual {...props} />;
  }
};

export default VisualSwitch;
