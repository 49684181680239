import { createSlice, createAction } from "@reduxjs/toolkit";
import { selectCurrentTovarId } from "./uiReducer";
const selectors = require("../../../back/src/common/selectors");
const priceSelector = require("../../../back/src/common/price");
const tovarSelectors = require("../../../back/src/common/tovar");
const {
  getOptions: getLeadOptions,
  isLeadEditable,
} = require("../../../back/src/common/lead");
const commonPromos = require("../../../back/src/common/promos");
const commonPrice = require("../../../back/src/common/price");

export const fetchLead = {
  pending: createAction("fetchLead/pending"),
  fulfilled: createAction("fetchLead/fulfilled"),
  rejected: createAction("fetchLead/rejected"),
};

const getInitialState = () => {
  return {
    data: {},
    defaults: {},
  };
};

export const leadSlice = createSlice({
  name: "lead",
  initialState: getInitialState(),
  reducers: {
    loadLeadData: (state, action) => {
      state.data = action.payload;
    },
    loadDefaults: (state, action) => {
      state.defaults = action.payload;
    },
    updateTovar: (state, action) => {
      // All the validation is done in the upstream thunk
      const i = state.data.tovars.findIndex((t) => t.id === action.payload.id);
      state.data.tovars[i] = action.payload;
    },
    updateLead: (state, action) => {
      // All the validation is done in the upstream thunk
      state.data = action.payload;
    },
    addNewTovar: (state, action) => {
      state.data.tovars.push(action.payload);
    },
    deleteTovar: (state, action) => {
      state.data.tovars = state.data.tovars.filter(
        (t) => t.id !== action.payload.id
      );
    },
  },
});

export const {
  loadLeadData,
  loadDefaults,
  updateTovar,
  updateLead,
  addNewTovar,
  deleteTovar,
} = leadSlice.actions;

export default leadSlice.reducer;

export const selectDefaults = (state) => state.lead.defaults;
export const selectLeadData = (state) => state.lead.data;
export const selectLeadId = (state) =>
  selectors.getLeadId(selectLeadData(state));
export const selectIsLeadEditable = (state) =>
  isLeadEditable(selectLeadData(state));
export const selectDefaultCategories = (state) =>
  selectors.getDefaultCategories(selectDefaults(state));
export const selectTovarNumber = (state) =>
  selectors.getTovarNumber(selectLeadData(state));
export const selectCurrentTovar = (state) => {
  return selectTovarById(selectCurrentTovarId(state))(state);
};
export const selectAllTovars = (state) =>
  selectors.getAllTovars(selectLeadData(state));
export const selectTovarById = (tovarId) => (state) => {
  const tovars = selectAllTovars(state);
  return tovars.find((t) => t.id === tovarId);
};

export const selectTovarPrice = (tovar) => (state) => {
  const defaults = selectDefaults(state);
  return priceSelector.getTovarPrice(defaults, tovar);
};

export const selectContact = (state) => {
  const lead = selectLeadData(state);
  return lead.contact;
};

export const selectComment = (state) => {
  const lead = selectLeadData(state);
  return lead.comment;
};

export const selectTovarDetails = (tovar) => (state) => {
  const defaults = selectDefaults(state);
  return tovarSelectors.getTovarDetails(defaults, tovar);
};

export const selectLeadOptions = (state) => {
  return getLeadOptions(selectDefaults(state));
};

export const selectAllPromos = (state) => {
  return selectDefaults(state).promos;
};

export const selectActivePromos = (state) => {
  return selectLeadData(state).activePromos;
};

export const selectBasketDiscounts = (state) => {
  const defaults = selectDefaults(state);
  const activePromos = selectActivePromos(state);
  return commonPromos.getDiscounts(defaults.promos, activePromos);
};

export const selectAvailablePromos = (state) => {
  const defaults = selectDefaults(state);
  const lead = selectLeadData(state);
  return commonPromos.getAvailablePromos(defaults.promos, lead);
};

export const getPriceWithDiscount = (price, discount) => {
  return commonPrice.getPriceWithDiscount(price, discount);
};

export const selectBasketTotal = (state) => {
  const defaults = selectDefaults(state);
  const lead = selectLeadData(state);
  return commonPrice.getLeadTotalPrice(defaults, lead);
};

export const selectTovarsInBasket = (state) => {
  const tovars = selectors.getAllTovarsInBasket(selectLeadData(state));
  const discounts = selectBasketDiscounts(state);
  return tovars.map((t) => {
    const details = selectTovarDetails(t)(state);
    return {
      tovar: t,
      details,
      discountPrice: getPriceWithDiscount(details.price, discounts[t.id]),
    };
  });
};

export const selectNumberOfTovarsInBasket = (state) => {
  const tovars = selectors.getAllTovarsInBasket(selectLeadData(state));
  return tovars.length;
};

export const rehydrate = () => {
  return getInitialState();
};
