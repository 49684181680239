export const getCustomField = (fieldId, data) => {
  return (
    data &&
    data.custom_fields_values &&
    data.custom_fields_values.find((field) => field.field_id === fieldId)
  );
};

export const getCustomFieldValue = (fieldId, data) => {
  const res = getCustomField(fieldId, data);
  if (!res || !res.values || !res.values[0]) {
    return null;
  }
  return res.values[0].enum_id || res.values[0].value;
};

export const getOptionByApiValue = (options, apiValue) => {
  if (!apiValue) {
    return null;
  }
  const res = options.find((obj) => obj.apiValue === apiValue);
  if (!res) {
    return null;
  }
  return res;
};

export const debouncePromise = (fn, delay = 1000) => {
  let timeout = null;
  let promises = [];

  return (...args) => {
    return new Promise((resolve, reject) => {
      clearTimeout(timeout);
      promises.push({ resolve, reject });
      timeout = setTimeout(() => {
        fn(...args)
          .then((...res) => {
            promises.forEach((p) => p.resolve(...res));
            promises = [];
          })
          .catch((...err) => {
            promises.forEach((p) => p.reject(...err));
            promises = [];
          });
      }, delay);
    });
  };
};
