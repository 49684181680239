import { Fragment, h } from "preact";
import styles from "./Tovar.module.css";
import VisualSwitch from "../VisualSwitch";
import DeleteButton from "./DeleteButton";

const TovarPrice = ({ price, discountPrice }) => {
  return price !== discountPrice ? (
    <Fragment>
      <span className={styles["price-old"]}>{price} руб.</span>
      <span className={styles.price}>{discountPrice} руб.</span>
    </Fragment>
  ) : (
    <span className={styles.price}>{price} руб.</span>
  );
};

const Tovar = ({ tovar, details, onEdit, onDelete, discountPrice }) => {
  return (
    <div className={styles["tovar-container"]}>
      <div className={styles.visual}>
        <VisualSwitch tovar={tovar} />
      </div>
      <div className={styles.info}>
        <div className={styles.header}>{details.title}</div>
        <div className={styles.details}>{details.subTitle}</div>
        <div>
          {onEdit && (
            <button className={styles.editButton} onClick={() => onEdit(tovar)}>
              Редактировать
            </button>
          )}
        </div>
      </div>
      <div className={styles.priceContainer}>
        <TovarPrice price={details.price} discountPrice={discountPrice} />
      </div>
      <div className={styles.deleteButtonContainer}>
        <DeleteButton onClick={() => onDelete(tovar)} />
      </div>
    </div>
  );
};

export default Tovar;
