import { route } from "preact-router";
import { getBackendURL } from "../../api";

export const pages = {
  CONSTRUCTOR: "CONSTRUCTOR",
  SHIPPING: "SHIPPING",
  MANAGER: "MANAGER",
  NEW: "NEW",
};

const getUrlForPage = (page) => {
  switch (page) {
    case pages.NEW:
      return "/new";
    case pages.SHIPPING:
      return "/shipping";
    case pages.MANAGER:
      return "/manager";
    default:
      return "/constructor";
  }
};

export const updateUrl = (page, leadId, replace = true, location = null) => {
  // Здесь разная логика если приложение само по себе и если оно в эмбеде
  const url = new URL(location || window.location);
  if (PREACT_APP_ENTRY !== "embed") {
    url.pathname = getUrlForPage(page);
  }
  url.searchParams.set("leadId", leadId);
  if (window.location.href !== url.href) {
    if (PREACT_APP_ENTRY === "embed") {
      window.history.replaceState({}, "", url);
    } else {
      route(url.pathname + url.search + url.hash, replace);
    }
  }
};

export const useRedirect = () => {
  const goToShipping = (leadId) => {
    if (PREACT_APP_ENTRY === "embed") {
      const url = new URL(getBackendURL());
      url.pathname = getUrlForPage(pages.SHIPPING);
      url.searchParams.set("leadId", leadId);
      window.location = url;
    } else {
      updateUrl(pages.SHIPPING, leadId, false);
    }
  };
  const goToConstructor = (leadId) =>
    updateUrl(pages.CONSTRUCTOR, leadId, false);
  return {
    goToShipping,
    goToConstructor,
  };
};
