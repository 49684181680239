import { getAssetURL } from "../api";
import { getOptionByValue } from "../components/utils";
import { selectCurrentTovar, selectDefaults } from "./leadReducer";
const commonHalat = require("../../../back/src/common/halat/selectors");

export const defaults = (state) => {
  return commonHalat.getOptions(
    selectDefaults(state),
    selectCurrentTovar(state)
  );
};

export const isFrontVishivkaOnly = (state) => {
  return selectCurrentTovar(state).vishivkaPos === "FRONT";
};

export const isBackVishivkaOnly = (state) => {
  return selectCurrentTovar(state).vishivkaPos === "BACK";
};

export const showFrontVishivka = (tovar) => (state) => {
  return tovar.vishivkaPos !== "BACK";
};

export const showBackVishivka = (tovar) => (state) => {
  return tovar.vishivkaPos !== "FRONT";
};

export const getImage = (tovar) => (state) => {
  if (tovar.sex === "CHILD") {
    const defaults = selectDefaults(state);
    const childType = getOptionByValue(
      defaults.halat.childType,
      tovar.childType
    );
    if (childType.tag !== "EMPTY") {
      return getAssetURL(childType.value);
    } else {
      return require("../assets/img/halat/child.png").default;
    }
  } else {
    return require("../assets/img/halat/adult.png").default;
  }
};

export const getHoodImage = (tovar) => (state) => {
  if (tovar.sex === "CHILD" || tovar.hood === "WITHOUT") {
    return false;
  }
  return require(`../assets/img/halat/hood_large.png`).default;
};

export const getChestImage = (tovar) => (state) => {
  const defaults = selectDefaults(state);
  const pic = getOptionByValue(defaults.halat.chestPic, tovar.chestPic);
  return pic && pic.tag !== "EMPTY" && pic.value;
};
