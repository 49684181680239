const { makeDefaultContact, applyContactUpdates } = require("./contact");
const { getDefaultCategories, getAllTovars } = require("./selectors");
const { makeDefaultTovar, getTovarDetails } = require("./tovar");
const { nanoid } = require("nanoid");
const { chooseCorrectValue, chooseCorrectSimpleValue } = require("./utils");
const { getLeadTotalPrice } = require("./price");
const { chooseCorrectPromos } = require("./promos");

const LEAD_STATUS = {
  NEW: 38248495, // НОВЫЙ КЛИЕНТ
  CONSTRUCTOR: 38903359, // НА КОНСТРУКТОРЕ
  SHIPPING: 38248498, // ОФОРМЛЕНИЕ ЗАЯВКИ
  PAYMENT: 38903362, // НА ОПЛАТЕ
  NOT_CONFIRMED: 39430087, // НЕПОДВЕРЖДЕННЫЕ
  PAID: 37068316, // ОПЛАЧЕН
  PROSROCHENO_CONTACT: 39752944, // ПРОСРОЧЕНО, НО ЕСТЬ КОНТАКТ
  PROSROCHENO: 39484162, // ПРОСРОЧЕНО
  OTLOZHENO: 39766591, // ОТЛОЖЕННЫЕ
  POD_ZAKAZ: 37301788, // ПОД ЗАКАЗ
};

// Это статусы, которые мы можем присваивать лиду
const statusOptions = [
  LEAD_STATUS.NEW,
  LEAD_STATUS.CONSTRUCTOR,
  LEAD_STATUS.SHIPPING,
  LEAD_STATUS.PAYMENT,
  LEAD_STATUS.NOT_CONFIRMED,
  LEAD_STATUS.PROSROCHENO_CONTACT,
  LEAD_STATUS.PROSROCHENO,
  LEAD_STATUS.OTLOZHENO,
  LEAD_STATUS.POD_ZAKAZ,
];

// Если лид в одном из этих статусов, то его можно редактировать
const changableStatuses = [
  LEAD_STATUS.NEW,
  LEAD_STATUS.CONSTRUCTOR,
  LEAD_STATUS.SHIPPING,
  LEAD_STATUS.PAYMENT,
  LEAD_STATUS.NOT_CONFIRMED,
  // LEAD_STATUS.PROSROCHENO_CONTACT,
  // LEAD_STATUS.PROSROCHENO,
  // LEAD_STATUS.OTLOZHENO,
  // LEAD_STATUS.POD_ZAKAZ,
];

const makeDefaultLead = (defaults, id, contactFields) => {
  const categories = getDefaultCategories(defaults);
  return {
    id,
    status: LEAD_STATUS.CONSTRUCTOR,
    tovars: [makeDefaultTovar(defaults, categories[0].value, nanoid())],
    activePromos: [],
    contact: makeDefaultContact(contactFields),
    comment: chooseCorrectValue(defaults.comment),
    deliveryType: chooseCorrectValue(defaults.deliveryType),
    predoplata: chooseCorrectValue(defaults.predoplata),
  };
};

const applyLeadUpdates = (defaults, lead, updates = {}) => {
  if (updates.status && updates.status !== lead.status) {
    validateLeadStatus(lead, updates.status);
  }
  return {
    id: lead.id,
    tovars: lead.tovars,
    activePromos: chooseCorrectPromos(
      defaults.promos,
      lead,
      updates.activePromos
    ),
    status: chooseCorrectValue(LEAD_STATUS.CONSTRUCTOR, [
      updates.status,
      lead.status,
    ]),
    contact: applyContactUpdates(lead.contact, updates),
    comment: chooseCorrectValue(defaults.comment, [
      updates.comment,
      lead.comment,
    ]),
    deliveryType: chooseCorrectValue(defaults.deliveryType, [
      updates.deliveryType,
      lead.deliveryType,
    ]),
    predoplata: chooseCorrectValue(defaults.predoplata, [
      updates.predoplata,
      lead.predoplata,
    ]),
  };
};

const getOptions = (defaults) => {
  return {
    comment: defaults.comment,
    deliveryType: defaults.deliveryType,
    predoplata: defaults.predoplata,
  };
};

const getLeadDetails = (defaults, lead) => {
  const titles = lead.tovars.reduce((res, t) => {
    res.push(getTovarDetails(defaults, t).title);
    return res;
  }, []);
  const price = getLeadTotalPrice(defaults, lead);
  return {
    description: titles.join(", "),
    price,
  };
};

const validateLeadStatus = (lead, newStatus = null) => {
  if (!changableStatuses.includes(lead.status)) {
    throw new Error(`Can't change a lead with a status ${lead.status}`);
  }
  if (newStatus && !statusOptions.includes(newStatus)) {
    throw new Error(`Can't change status to ${newStatus}`);
  }
  return lead;
};

const isLeadEditable = (lead) => {
  return changableStatuses.includes(lead.status);
};

module.exports = {
  makeDefaultLead,
  applyLeadUpdates,
  LEAD_STATUS,
  getOptions,
  getLeadDetails,
  validateLeadStatus,
  isLeadEditable,
};
