const { getOptionByValue, getOptionsByValues } = require("../selectors");
const { calculatePriceFromFields } = require("../utils");

const getSizeOptions = (defaults, tovar) => {
  return tovar.sex === "CHILD"
    ? defaults.halat.size.child
    : defaults.halat.size.adult;
};

const getColorOptions = (defaults, tovar) => {
  return tovar.sex === "CHILD"
    ? defaults.halat.color.child
    : defaults.halat.color.adult;
};

const getOptions = (defaults, tovar) => {
  return {
    size: getSizeOptions(defaults, tovar),
    color: getColorOptions(defaults, tovar),
    sex: defaults.halat.sex,
    fabric: defaults.halat.fabric,
    vishivkaPos: defaults.halat.vishivkaPos,
    chestPic: defaults.halat.chestPic,
    childType: defaults.halat.childType,
    hood: defaults.halat.hood,
  };
};

const calculatePrice = (defaults, tovar) => {
  const options = getOptions(defaults, tovar);
  return calculatePriceFromFields(options, tovar);
};

const getDetails = (defaults, tovar) => {
  const sex = getOptionByValue(defaults.halat.sex, tovar.sex);
  return {
    title: `Халат ${sex.title}`,
    subTitle: `Размер: ${tovar.size}`,
    price: calculatePrice(defaults, tovar),
  };
};

const getTelegramDescription = (defaults, tovar) => {
  const options = getOptions(defaults, tovar);
  const values = getOptionsByValues(options, tovar);

  const lines = [
    `Халат ${values.sex.title} ${values.hood.title}`,
    `Ткань: ${values.fabric.title}`,
    `Размер: ${values.size.title}`,
    `Цвет: ${values.color.title}`,
  ];
  return lines.join("\n  ");
};

module.exports = {
  getSizeOptions,
  getColorOptions,
  getOptions,
  calculatePrice,
  getDetails,
  getTelegramDescription,
};
