import { Fragment, h } from "preact";
import ErrorManager from "./ErrorManager";
import styles from "./App.module.css";
import ConstructorRoute from "./routes/ConstructorRoute";
import { useEffect } from "preact/hooks";
import { Provider, useDispatch } from "react-redux";
import store from "../store";
import { fetchDefaults } from "../store/apiActions";

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDefaults());
  }, []);
  const leadId = new URL(window.location).searchParams.get("leadId");

  return (
    <Fragment>
      <ErrorManager />
      <main id="app" className={styles.app}>
        <ConstructorRoute path="/constructor" leadId={leadId} />
      </main>
    </Fragment>
  );
};

export default () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};
