import { createAction, nanoid } from "@reduxjs/toolkit";
import API from "../api/index";
import { pages, updateUrl } from "../components/URLManager";
import {
  selectLeadId,
  selectCurrentTovar,
  selectDefaults,
  selectLeadData,
  selectAllTovars,
  selectActivePromos,
} from "./leadReducer";
import * as leadActions from "./leadReducer";
import * as uiActions from "./uiReducer";
import { addError, selectCurrentTovarId } from "./uiReducer";
import { checkAppliedPromos } from "./promos";
const {
  applyTovarUpdates,
  makeDefaultTovar,
} = require("../../../back/src/common/tovar");
const { applyLeadUpdates } = require("../../../back/src/common/lead");

const makeServerRequest = async (dispatch, request, makeError) => {
  const reqId = nanoid();
  dispatch(leadActions.fetchLead.pending(reqId));
  try {
    const data = await request();
    dispatch(leadActions.fetchLead.fulfilled({ reqId, data }));
  } catch (err) {
    console.error(err);
    dispatch(leadActions.fetchLead.rejected({ reqId, data: makeError() }));
  }
};

export const setLeadId = (leadId, page) => {
  return async (dispatch, getState) => {
    if (leadId && selectLeadId(getState()) === leadId) {
      return;
    }
    makeServerRequest(
      dispatch,
      async () => {
        const data = await (leadId
          ? API.loadLeadByID(leadId)
          : API.createNewLead());
        updateUrl(page, data.id, true);
        dispatch(leadActions.loadLeadData(data));
        return data;
      },
      () =>
        leadId
          ? `Не удалось загрузить заказ №${leadId}`
          : `Не удалось создать новый заказ`
    );
  };
};

export const fetchDefaults = () => {
  return async (dispatch, getState) => {
    makeServerRequest(
      dispatch,
      async () => {
        const data = await API.loadDefaults();
        dispatch(leadActions.loadDefaults(data));
        return data;
      },
      () => `Не удалось загрузить данные с сервера`
    );
  };
};

export const updateTovar = (updates) => {
  return async (dispatch, getState) => {
    const leadId = selectLeadId(getState());
    const tovar = selectCurrentTovar(getState());
    const defaults = selectDefaults(getState());
    const updatedTovar = applyTovarUpdates(defaults, tovar, updates);

    // Не ждем окончания этого запроса, пусть будет в бэкграунде
    API.sendTovarUpdates(leadId, updatedTovar).catch((err) => {
      dispatch(addError("Не удалось отправить данные на сервер."));
    });

    dispatch(leadActions.updateTovar(updatedTovar));
    dispatch(checkAppliedPromos());
  };
};

export const updateLead = (updates) => {
  return async (dispatch, getState) => {
    const leadId = selectLeadId(getState());
    const defaults = selectDefaults(getState());
    const lead = selectLeadData(getState());
    const updatedLead = applyLeadUpdates(defaults, lead, updates);

    // Не ждем окончания этого запроса, пусть будет в бэкграунде
    API.sendLeadUpdates(updatedLead).catch((err) => {
      dispatch(addError("Не удалось отправить данные на сервер."));
    });

    dispatch(leadActions.updateLead(updatedLead));
    dispatch(checkAppliedPromos());
  };
};

export const addNewTovar = (
  inBasket = false,
  template = { category: "HALAT" }
) => {
  return async (dispatch, getState) => {
    const leadId = selectLeadId(getState());
    const defaults = selectDefaults(getState());
    let newTovar = makeDefaultTovar(
      defaults,
      template.category,
      nanoid(),
      inBasket
    );
    newTovar = applyTovarUpdates(defaults, newTovar, template);

    // Не ждем окончания этого запроса, пусть будет в бэкграунде
    API.createNewTovar(leadId, newTovar).catch((err) => {
      dispatch(addError("Не удалось отправить данные на сервер."));
    });

    dispatch(leadActions.addNewTovar(newTovar));
    dispatch(uiActions.setCurrentTovar(newTovar));
    dispatch(checkAppliedPromos());
    return newTovar;
  };
};

export const deleteTovar = (tovar) => {
  return async (dispatch, getState) => {
    const leadId = selectLeadId(getState());
    const allTovars = selectAllTovars(getState());
    if (allTovars.length <= 1) {
      dispatch(updateTovar({ inBasket: false }));
      return;
    }
    const currentTovarId = selectCurrentTovarId(getState());

    // Не ждем окончания этого запроса, пусть будет в бэкграунде
    API.sendDeleteTovar(leadId, tovar).catch((err) => {
      dispatch(addError("Не удалось отправить данные на сервер."));
    });

    dispatch(leadActions.deleteTovar(tovar));
    if (currentTovarId === tovar.id) {
      const allTovars = selectAllTovars(getState());
      dispatch(uiActions.setCurrentTovar(allTovars[allTovars.length - 1]));
    }

    dispatch(checkAppliedPromos());
  };
};
