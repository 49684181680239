import { selectCurrentTovar, selectDefaults } from "./leadReducer";
const commonVishivka = require("../../../back/src/common/vishivka/selectors");
const { getOptionByValue } = require("../../../back/src/common/selectors");

export const defaults = (state) => {
  return commonVishivka.getOptions(
    selectDefaults(state),
    selectCurrentTovar(state)
  );
};

export const upperPicOptionsByCategory = (state) => {
  return defaults(state).upperPic.reduce((acc, opt) => {
    if (!opt.category) return acc;
    acc[opt.category] = acc[opt.category] || [];
    acc[opt.category].push(opt);
    return acc;
  }, {});
};

export const isNeedsInitials = (state) => {
  const defaults = selectDefaults(state);
  const tovar = selectCurrentTovar(state);
  const chestPic = getOptionByValue(defaults.halat.chestPic, tovar.chestPic);
  const upperPic = getOptionByValue(defaults.vishivka.upperPic, tovar.upperPic);
  const lowerPic = getOptionByValue(defaults.vishivka.lowerPic, tovar.lowerPic);

  return (
    (chestPic && chestPic.initials) ||
    (upperPic && upperPic.initials) ||
    (lowerPic && lowerPic.initials)
  );
};

export const getUpperPic = (tovar) => (state) => {
  const defaults = selectDefaults(state);
  const res = {
    ...getOptionByValue(defaults.vishivka.upperPic, tovar.upperPic),
  };
  if (res.value === "USER_UPLOAD") {
    res.value = tovar.imgURL;
  }
  return res;
};

export const getLowerPic = (tovar) => (state) => {
  const defaults = selectDefaults(state);
  return getOptionByValue(defaults.vishivka.lowerPic, tovar.lowerPic);
};

export const getDefaultsForTovar = (tovar) => (state) => {
  return commonVishivka.getOptions(selectDefaults(state), tovar);
};
