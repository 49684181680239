const { getOptionByValue, getOptionsByValues } = require("../selectors");
const { calculatePriceFromFields } = require("../utils");

const getOptions = (defaults, tovar) => {
  return {
    design: defaults.polotence.design,
    orientation: defaults.polotence.orientation,
    size: defaults.polotence.size,
    color: defaults.polotence.color,
  };
};

const calculatePrice = (defaults, tovar) => {
  const options = getOptions(defaults, tovar);
  return calculatePriceFromFields(options, tovar);
};

const getDetails = (defaults, tovar) => {
  return {
    title: `Полотенце`,
    subTitle: `Размер: ${tovar.size}`,
    price: calculatePrice(defaults, tovar),
  };
};

const getTelegramDescription = (defaults, tovar) => {
  const options = getOptions(defaults, tovar);
  const values = getOptionsByValues(options, tovar);

  const lines = [
    `Полотенце`,
    `Размер: ${values.size.title}`,
    `Цвет: ${values.color.title}`,
    `Ориентация вышивки: ${values.orientation.title}`,
  ];
  return lines.join("\n  ");
};

module.exports = {
  getOptions,
  calculatePrice,
  getDetails,
  getTelegramDescription,
};
