const { chooseCorrectValue } = require("../utils");

const applyUpdates = (defaults, tovar, updates) => {
  const res = {};
  res.text = chooseCorrectValue(defaults.vishivka.text, [
    updates.text,
    tovar.text,
  ]);
  res.initialsText = chooseCorrectValue(defaults.vishivka.initialsText, [
    updates.initialsText,
    tovar.initialsText,
  ]);
  res.textColor = chooseCorrectValue(defaults.vishivka.textColor, [
    updates.textColor,
    tovar.textColor,
  ]);
  res.lowerPic = chooseCorrectValue(defaults.vishivka.lowerPic, [
    updates.lowerPic,
    tovar.lowerPic,
  ]);
  res.font = chooseCorrectValue(defaults.vishivka.font, [
    updates.font,
    tovar.font,
  ]);
  res.fontSize = chooseCorrectValue(defaults.vishivka.fontSize, [
    updates.fontSize,
    tovar.fontSize,
  ]);
  if (updates.upperPic && updates.upperPic.tag === "USER_UPLOAD") {
    res.upperPic = "USER_UPLOAD";
    res.imgURL = updates?.upperPic?.imgURL;
  } else {
    res.upperPic = chooseCorrectValue(defaults.vishivka.upperPic, [
      updates.upperPic,
      tovar.upperPic,
    ]);
    if (res.upperPic === "USER_UPLOAD") {
      res.imgURL = tovar.imgURL;
    }
  }
  res.isTextSetByUser = tovar.isTextSetByUser;
  if (!res.isTextSetByUser) {
    res.isTextSetByUser = res.text !== defaults.vishivka.text;
  }
  return res;
};

const makeDefault = (defaults) => {
  return applyUpdates(defaults, {}, {});
};

module.exports = {
  applyUpdates,
  makeDefault,
};
