import { Fragment, h } from "preact";
import styles from "./Totals.module.css";

const Totals = ({ total, totalWithDiscount }) => {
  return (
    <div className={styles.total}>
      <span className={styles.fullText}>Итоговая стоимость:</span>
      <span className={styles.smallText}>Итого:</span>
      <div className={styles.priceContainer}>
        {total !== totalWithDiscount ? (
          <Fragment>
            <span className={styles["price-old"]}>{total} руб.</span>
            <span className={styles.price}>{totalWithDiscount} руб.</span>
          </Fragment>
        ) : (
          <span className={styles.price}>{total} руб.</span>
        )}
      </div>
    </div>
  );
};

export default Totals;
