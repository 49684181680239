import { h } from "preact";
import makeRadioButtonRow from ".";
import { getAssetURL } from "../../api";
import styles from "./RadioButtonRowToyMetric.module.css";

const ListItemToyMetric = ({ option, isSelected, onClick }) => {
  const classes = [styles.listItem];
  if (isSelected) classes.push(styles.active);
  return (
    <li key={option.value} className={classes.join(" ")} onClick={onClick}>
      <div className={styles.innerItem}>
        <img src={getAssetURL(option.value)} alt={option.title} />
      </div>
    </li>
  );
};

const RadioButtonRowToyMetric = makeRadioButtonRow(ListItemToyMetric);

export default RadioButtonRowToyMetric;
