import { h } from "preact";
import styles from "./TopCategoriesList.module.css";

const TopCategoriesList = ({ options, selected, onChange }) => {
  return (
    <ul className={styles.list}>
      {options.map((opt) => {
        const classes = [styles.listItem];
        if (selected === opt.value) classes.push(styles.active);
        return (
          <li
            key={opt.value}
            className={classes.join(" ")}
            onClick={() => onChange(opt)}
          >
            {opt.title}
          </li>
        );
      })}
    </ul>
  );
};

export default TopCategoriesList;
