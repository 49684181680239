import { h } from "preact";
import styles from "./CloseButton.module.css";

const CloseButton = ({ onClick, className = "" }) => (
  <div
    onClick={(e) => onClick(e)}
    className={[styles.closeButton, className].join(" ")}
  >
    <svg
      viewBox="0 0 40 40"
      enable-background="new 0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="7"
        y1="7"
        x2="33"
        y2="33"
        stroke="#fff"
        stroke-width="5"
        stroke-linecap="round"
        stroke-miterlimit="10"
      ></line>
      <line
        x1="33"
        y1="7"
        x2="7"
        y2="33"
        stroke="#fff"
        stroke-width="5"
        stroke-linecap="round"
        stroke-miterlimit="10"
      ></line>
    </svg>
    <span>Закрыть</span>
  </div>
);

export default CloseButton;
