const { getOptionByValue } = require("../selectors");
const { calculatePriceFromFields } = require("../utils");

const getOptions = (defaults, tovar) => {
  return {
    fabric: defaults.podushka.fabric,
    color: defaults.podushka.color,
  };
};

const calculatePrice = (defaults, tovar) => {
  const options = getOptions(defaults, tovar);
  return calculatePriceFromFields(options, tovar);
};

const getDetails = (defaults, tovar) => {
  const fabric = getOptionByValue(defaults.podushka.fabric, tovar.fabric);
  return {
    title: `Подушка ${fabric.title}`,
    subTitle: ``,
    price: calculatePrice(defaults, tovar),
  };
};

module.exports = {
  getOptions,
  calculatePrice,
  getDetails,
};
