const { getSizeOptions, getColorOptions } = require("./selectors");
const { chooseCorrectValue } = require("../utils");

const applyUpdates = (defaults, tovar, updates) => {
  const res = {};
  res.sex = chooseCorrectValue(defaults.halat.sex, [updates.sex, tovar.sex]);
  res.fabric = chooseCorrectValue(defaults.halat.fabric, [
    updates.fabric,
    tovar.fabric,
  ]);
  res.size = chooseCorrectValue(getSizeOptions(defaults, res), [
    updates.size,
    tovar.size,
  ]);
  res.color = chooseCorrectValue(getColorOptions(defaults, res), [
    updates.color,
    tovar.color,
  ]);
  res.vishivkaPos = chooseCorrectValue(defaults.halat.vishivkaPos, [
    updates.vishivkaPos,
    tovar.vishivkaPos,
  ]);
  res.chestPic = chooseCorrectValue(defaults.halat.chestPic, [
    updates.chestPic,
    tovar.chestPic,
  ]);

  if (res.sex === "CHILD") {
    res.childType = chooseCorrectValue(defaults.halat.childType, [
      updates.childType,
      tovar.childType,
    ]);
  } else {
    res.hood = chooseCorrectValue(defaults.halat.hood, [
      updates.hood,
      tovar.hood,
    ]);
  }

  return res;
};

const makeDefault = (defaults) => {
  return applyUpdates(defaults, {}, {});
};

module.exports = {
  applyUpdates,
  makeDefault,
  getSizeOptions,
  getColorOptions,
};
