import { h } from "preact";
import { useRef, useState } from "preact/hooks";
import styles from "./UploadImageButton.module.css";
import { useError } from "../utils";
import { useSelector } from "react-redux";
import API from "../../api/index";
import { selectCurrentTovar } from "../../store/leadReducer";
import Spinner from "../Spinner";

const isValidFileSize = (file) => {
  return file.size <= 10000000;
};

const acceptedTypes = ["image/jpeg", "image/png", "image/svg+xml"];
const isValidFileType = (file) => {
  return acceptedTypes.includes(file.type);
};

const UploadImageButton = ({ onChange }) => {
  const { addError } = useError();
  const tovar = useSelector(selectCurrentTovar);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);

  const onFileChange = async (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const file = files[0];
      if (!isValidFileType(file)) {
        addError(
          "Неверный тип файла. Пожалуйста, загрузите файл .png, .jpg или .svg."
        );
        return false;
      }
      if (!isValidFileSize(file)) {
        addError(
          "Файл превышает максимальный размер 10 мегабайт. Пожалуйста, загрузите файл поменьше."
        );
        return false;
      }
      setIsLoading(true);
      const imgURL = await API.uploadUserImage(tovar.id, file);
      onChange({
        tag: "USER_UPLOAD",
        imgURL,
      });
      setIsLoading(false);
    }
  };

  return (
    <div>
      <label for="upload-pic" className={styles.button}>
        {isLoading ? (
          <Spinner className={styles.spinner} />
        ) : (
          "Загрузить свой рисунок"
        )}
      </label>
      <input
        className={styles.fileInput}
        id="upload-pic"
        ref={inputRef}
        type="file"
        accept={acceptedTypes.join(",")}
        onChange={onFileChange}
      />
    </div>
  );
};

export default UploadImageButton;
