import { useDispatch, useSelector } from "react-redux";
import { updateLead, updateTovar } from "../store/apiActions";
import {
  selectDefaults,
  selectLeadData,
  selectNumberOfTovarsInBasket,
  updateLead as updateLeadAction,
} from "../store/leadReducer";
import { addError, clearErrors } from "../store/uiReducer";
import API from "../api/index";
const { applyLeadUpdates } = require("../../../back/src/common/lead");

export const useUpdateTovar = () => {
  const dispatch = useDispatch();
  return (key) => (update) =>
    dispatch(
      updateTovar({
        [key]: update.value !== undefined ? update.value : update,
      })
    );
};

export const useUpdateLead = () => {
  const dispatch = useDispatch();
  return (key) => (update) =>
    dispatch(
      updateLead({
        [key]: update.value !== undefined ? update.value : update,
      })
    );
};

export const useImageUpload = () => {
  const dispatch = useDispatch();
  return (key) => (update) =>
    dispatch(
      updateTovar({
        [key]: update.value,
        imgURL: URL.createObjectURL(update.file),
      })
    );
};

export const useError = () => {
  const dispatch = useDispatch();
  return {
    addError: (text) => dispatch(addError(text)),
    clearErrors: () => dispatch(clearErrors()),
  };
};

export const getOptionByValue = (options, value) => {
  return options.find((o) => o.value === value);
};

export const useUpdateLeadStatus = () => {
  const dispatch = useDispatch();
  const defaults = useSelector(selectDefaults);
  const lead = useSelector(selectLeadData);

  return async (newStatus) => {
    const updatedLead = applyLeadUpdates(defaults, lead, { status: newStatus });
    try {
      await API.sendLeadUpdates(updatedLead);
      dispatch(updateLeadAction(updatedLead));
      return updatedLead;
    } catch (err) {
      console.error(err);
      dispatch(addError("Не удалось обновить статус заказа."));
      throw err;
    }
  };
};

export const usePayment = () => {
  const dispatch = useDispatch();
  return async (leadId) => {
    try {
      const paymentLink = await API.loadPaymentLink(leadId, false);
      return paymentLink;
    } catch (err) {
      console.error("Error loading payment link leadbyID:", err);
      dispatch(addError("Не получилось создать ссылку для оплаты"));
      return null;
    }
  };
};

export const isPhoneValid = (phone) => {
  const match = phone.match(/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/);
  return match !== null;
};

export const useContact = () => {
  const { addError, clearErrors } = useError();
  const validateContact = (contact) => {
    // validate stuff if ok, update status and redirect
    if (contact.name === "") {
      addError("Пожалуйста, введите ваше имя");
      return false;
    } else if (contact.phone === "") {
      addError("Пожалуйста, введите номер телефона для связи");
      return false;
    } else if (!isPhoneValid(contact.phone)) {
      addError(
        "Пожалуйста, введите нормер телефона в формате +7 (111) 111-11-11"
      );
      return false;
    } else {
      clearErrors();
      return true;
    }
  };

  const validateShipping = (contact) => {
    if (contact.name === "") {
      addError("Пожалуйста, введите ваше имя");
      return false;
    } else if (!isPhoneValid(contact.phone)) {
      addError(
        "Пожалуйста, введите нормер телефона в формате +7 (111) 111-11-11"
      );
      return false;
    } else if (contact.address === "") {
      addError(
        "Пожалуйста, введите адрес доставки: индекс, город, улицу, дом, квартиру"
      );
      return false;
    } else {
      clearErrors();
      return true;
    }
  };

  return { validateContact, validateShipping };
};

export const useCheckBasket = () => {
  const { addError, clearErrors } = useError();
  const tovarsNumber = useSelector(selectNumberOfTovarsInBasket);
  return () => {
    if (tovarsNumber <= 0) {
      addError("Пожалуйста, добавьте товар в корзину");
      return false;
    } else {
      clearErrors();
      return true;
    }
  };
};

export const scrollToTop = () => {
  const container =
    document.getElementById("constructor-container") || document.body;
  const box = container.getBoundingClientRect();

  const body = document.body;
  const docEl = document.documentElement;

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  const clientTop = docEl.clientTop || body.clientTop || 0;

  const top = Math.round(box.top + scrollTop - clientTop);

  window.scrollTo(0, top);
};
