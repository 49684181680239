import { h } from "preact";
import { useCallback, useEffect, useRef } from "preact/hooks";
import { useSelector, useDispatch } from "react-redux";
import { selectError, clearErrors } from "../../store/uiReducer";
import Error from "./Error";

const ErrorManager = () => {
  const error = useSelector(selectError);
  const dispatch = useDispatch();
  const timeoutRef = useRef(null);

  const closeError = useCallback(() => dispatch(clearErrors()), [clearErrors]);

  useEffect(() => {
    if (error) {
      timeoutRef.current = setTimeout(closeError, 5000);
      return () => timeoutRef.current && clearTimeout(timeoutRef.current);
    }
  }, [error]);

  return <Error text={error} close={closeError} />;
};

export default ErrorManager;
