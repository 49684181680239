import { h } from "preact";

const ArrowIcon = () => (
  <svg
    width="8"
    height="4"
    viewBox="0 0 8 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.21953 3.91561L7.90953 0.489905C8.03036 0.377666 8.03016 0.195949 7.90891 0.0838976C7.78767 -0.0280667 7.59128 -0.0277774 7.47036 0.0844762L3.99999 3.30627L0.529624 0.0843602C0.408687 -0.027879 0.212421 -0.0281683 0.0911717 0.0837815C0.0303907 0.139952 -9.33409e-09 0.213539 -1.25507e-08 0.287125C-1.5759e-08 0.360524 0.0301876 0.433822 0.0905466 0.489891L3.78045 3.91561C3.83855 3.96967 3.91761 4 3.99999 4C4.08236 4 4.16133 3.96958 4.21953 3.91561Z"
      fill="#91949F"
    />
  </svg>
);

export default ArrowIcon;
