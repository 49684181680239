import { h } from "preact";
import styles from "./TovarList.module.css";
import Tovar from "./Tovar";

const TovarList = ({ tovars, onEdit, onDelete }) => {
  return (
    <div className={styles.tovarList}>
      {tovars.map(({ tovar, details, discountPrice }) => {
        return (
          <Tovar
            key={tovar.id}
            tovar={tovar}
            details={details}
            onEdit={onEdit}
            onDelete={onDelete}
            discountPrice={discountPrice}
          />
        );
      })}
    </div>
  );
};

export default TovarList;
