const { getOptionByValue } = require("../selectors");

const getOptions = (defaults, tovar) => {
  return {
    toyType: defaults.toy.toyType,
    metricType: defaults.toy.metricType,
    dopText: defaults.toy.dopText,
  };
};

const calculatePrice = (defaults, tovar) => {
  const toyType = getOptionByValue(defaults.toy.toyType, tovar.toyType);
  let price = toyType.price;
  if (isShowDopText(defaults, tovar) && tovar.dopText !== "") {
    price += defaults.toy.dopTextAddPrice;
  }
  return price;
};

const isShowDopText = (defaults, tovar) => {
  const toyType = getOptionByValue(defaults.toy.toyType, tovar.toyType);
  return toyType.tag === "ZAYA";
};

const getDetails = (defaults, tovar) => {
  const toyType = getOptionByValue(defaults.toy.toyType, tovar.toyType);
  return {
    title: `Игрушка ${toyType.value}`,
    subTitle: ``,
    price: calculatePrice(defaults, tovar),
  };
};

module.exports = {
  getOptions,
  calculatePrice,
  isShowDopText,
  getDetails,
};
