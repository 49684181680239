const testData = require("../../../back/src/common/__tests__/data");
const { makeDefaultLead } = require("../../../back/src/common/lead");

export const leadWithFields = {
  data: makeDefaultLead(testData.defaults, "1", {}),
};

export const defaults = {
  data: testData.defaults,
};
