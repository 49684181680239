import axios from "axios";
import attachMock from "./mock";
import { debouncePromise } from "./utils";

export const getBackendURL = () =>
  PREACT_APP_API_ENDPOINT ||
  `${location.protocol}//${window.location.hostname}`;

const host =
  process.env.NODE_ENV === "development"
    ? `${location.protocol}//${window.location.hostname}:3000`
    : getBackendURL();

export const getAssetURL = (path) => {
  return new URL(path, host).toString();
};

const apiEndpoint = getAssetURL("api");

if (process.env.NODE_ENV === "development" && PREACT_APP_MOCK) {
  attachMock(axios, apiEndpoint);
}

const API = {
  loadLeadByID: async (leadId) => {
    return axios
      .get(`${apiEndpoint}/leads/${leadId}`)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        // Если лид не найден, создаем новый лид
        if (err.response.status === 404) {
          return API.createNewLead();
        } else {
          console.error(
            `Request to ${err.config.url} failed with error: ${err.message}`
          );
          throw err;
        }
      });
  },
  createNewLead: async (data = {}) => {
    return axios.post(`${apiEndpoint}/leads`, data).then((res) => {
      return res.data.data;
    });
  },
  loadDefaults: async () => {
    return axios.get(`${apiEndpoint}/defaults`).then((res) => {
      return res.data.data;
    });
  },
  sendTovarUpdates: debouncePromise(async (leadId, tovar) => {
    return axios
      .patch(`${apiEndpoint}/leads/${leadId}/tovar/${tovar.id}`, {
        data: tovar,
      })
      .then((res) => {});
  }),
  createNewTovar: async (leadId, tovar) => {
    return axios
      .post(`${apiEndpoint}/leads/${leadId}/tovar`, { data: tovar })
      .then((res) => {});
  },
  sendDeleteTovar: async (leadId, tovar) => {
    return axios
      .delete(`${apiEndpoint}/leads/${leadId}/tovar/${tovar.id}`)
      .then((res) => {});
  },
  sendLeadUpdates: debouncePromise(async (lead) => {
    return axios.patch(`${apiEndpoint}/leads/${lead.id}`, { data: lead });
  }),
  uploadUserImage: async (tovarId, file) => {
    const formData = new FormData();
    formData.append("tovarId", tovarId);
    formData.append("file", file);
    return axios
      .post(`${apiEndpoint}/image/${tovarId}`, formData)
      .then((res) => {
        return res.data.imageUrl;
      });
  },
  loadPaymentLink: async (leadId) => {
    return axios.get(`${apiEndpoint}/leads/${leadId}/payment`).then((res) => {
      return res.data.data.paymentLink;
    });
  },
  checkPaymentStatus: async (leadId) => {
    return axios.patch(`${apiEndpoint}/leads/${leadId}/payment`).then((res) => {
      return res.data.data.paymentSuccess;
    });
  },
};

export default API;
