import { h } from "preact";
import { useRef, useEffect } from "preact/hooks";
import styles from "./Input.module.css";

const mask = "+7 (___) ___-__-__";
const getMask = (value) => {
  return mask.slice(value.length);
};

const formatValue = (value, cursorPos = 0) => {
  const before = value.slice(0, cursorPos).replace(/\D/g, "");
  const after = value.slice(cursorPos).replace(/\D/g, "");
  const match = (before + after).match(
    /^7?(\d{1,3})?(\d{1,3})?(\d{1,2})?(\d{1,2})?/
  );
  const groups = ["+7 ("];
  if (match[1]) {
    groups.push(match[1]);
    if (match[1].length === 3) groups.push(") ");
  }
  if (match[2]) {
    groups.push(match[2]);
    if (match[2].length === 3) groups.push("-");
  }
  if (match[3]) {
    groups.push(match[3]);
    if (match[3].length === 2) groups.push("-");
  }
  if (match[4]) groups.push(match[4]);

  let newPos = before.length + 3;
  if (before.length >= 4) newPos += 2;
  if (before.length >= 7) newPos += 1;
  if (before.length >= 9) newPos += 1;
  return {
    value: groups.join(""),
    cursorPos: newPos,
  };
};

const useRunAfterPaint = () => {
  const fn = useRef(null);
  useEffect(() => {
    if (fn.current) {
      fn.current();
      fn.current = null;
    }
  });
  return (f) => (fn.current = f);
};

const updateCursor = (input, newPos) => {
  input.selectionStart = newPos;
  input.selectionEnd = newPos;
};

const PhoneInput = ({
  name = "",
  value = "",
  disabled = false,
  onChange,
  className = "",
}) => {
  const runAfterPaint = useRunAfterPaint();

  const updateInputValue = (val, input, moveCursorTo) => {
    const { value: newValue, cursorPos } = formatValue(val, moveCursorTo);
    if (newValue !== input.value) {
      input.value = newValue;
    }
    runAfterPaint(updateCursor(input, cursorPos));
    // This means the component will not be re-rendered, so the cursor won't update
    if (newValue === value) {
      updateCursor(input, cursorPos);
    }
    onChange(newValue);
  };

  const onKeyDown = (e) => {
    // Backspace
    if (e.keyCode === 8) {
      e.preventDefault();
      const isSelection = e.target.selectionStart !== e.target.selectionEnd;
      const before = e.target.value
        .slice(0, e.target.selectionStart)
        .replace(/\D/g, "");
      const after = e.target.value
        .slice(e.target.selectionEnd)
        .replace(/\D/g, "");
      const val =
        before.slice(0, before.length - (isSelection ? 0 : 1)) + after;
      updateInputValue(val, e.target, before.length - (isSelection ? 0 : 1));
    }
    // Delete
    if (e.keyCode === 46) {
      e.preventDefault();
      const isSelection = e.target.selectionStart !== e.target.selectionEnd;
      const before = e.target.value
        .slice(0, e.target.selectionStart)
        .replace(/\D/g, "");
      const after = e.target.value
        .slice(e.target.selectionEnd)
        .replace(/\D/g, "");
      const val = before + after.slice(isSelection ? 0 : 1);
      updateInputValue(val, e.target, before.length);
    }
  };

  const onChangeEvent = (e) => {
    updateInputValue(e.target.value, e.target, e.target.selectionStart);
  };
  const { value: displayValue } = formatValue(value);

  return (
    <div className={[styles.phoneWrapper, className].join(" ")}>
      <div className={styles.phoneMask}>
        <span style={{ visibility: "hidden" }}>{displayValue}</span>
        <span>{getMask(displayValue)}</span>
      </div>
      <input
        className={styles.field}
        type="tel"
        disabled={disabled}
        name={name}
        value={displayValue}
        onInput={onChangeEvent}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export default PhoneInput;
