import {
  selectLeadData,
  selectDefaults,
  selectActivePromos,
} from "./leadReducer";
import { addNewTovar, updateLead } from "./apiActions";
const common = require("../../../back/src/common/promos");

export const applyPromo = (promo) => {
  return async (dispatch, getState) => {
    const lead = selectLeadData(getState());
    const promoObject = {
      id: promo.data.id,
      usedTovars: promo.usedTovars,
      giftTovars: [],
    };
    if (promo.data.reward.gift) {
      for (const template of promo.data.reward.gift) {
        const newTovar = await addNewTovar(true, template)(dispatch, getState);
        promoObject.giftTovars.push(newTovar.id);
      }
    }
    const activePromos = [...lead.activePromos];
    activePromos.push(promoObject);

    dispatch(updateLead({ activePromos }));
  };
};

export const checkAppliedPromos = () => {
  return async (dispatch, getState) => {
    const lead = selectLeadData(getState());
    const allPromos = selectDefaults(getState()).promos;
    const activePromos = selectActivePromos(getState());

    const newActivePromos = common.chooseCorrectPromos(allPromos, lead);
    if (newActivePromos.length !== activePromos.length) {
      dispatch(updateLead({ activePromos: newActivePromos }));
    }
  };
};
