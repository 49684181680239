import { h } from "preact";
import styles from "./ErrorManager.module.css";

const Error = ({ text, close }) => {
  const classes = [styles.container];
  classes.push(text ? styles.visible : styles.hidden);

  return (
    <div className={classes.join(" ")}>
      <div className={styles.text}>
        <img
          alt=""
          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDQgMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjY3MzA3NyA4LjcyNTQ5TDAuMDM4NDYxNSAzLjkyMTU3VjBIMy45NjE1NFYzLjkyMTU3TDMuMzI2OTIgOC43MjU0OUgwLjY3MzA3N1pNMy40MDM4NSAxMy40MTE4QzMuMDE5MjMgMTMuODAzOSAyLjU1MTI4IDE0IDIgMTRDMS40NDg3MiAxNCAwLjk3NDM1OSAxMy44MDM5IDAuNTc2OTIzIDEzLjQxMThDMC4xOTIzMDggMTMuMDE5NiAwIDEyLjUzNTkgMCAxMS45NjA4QzAgMTEuMzk4NyAwLjE5MjMwOCAxMC45MjE2IDAuNTc2OTIzIDEwLjUyOTRDMC45NzQzNTkgMTAuMTI0MiAxLjQ0ODcyIDkuOTIxNTcgMiA5LjkyMTU3QzIuNTUxMjggOS45MjE1NyAzLjAxOTIzIDEwLjEyNDIgMy40MDM4NSAxMC41Mjk0QzMuODAxMjggMTAuOTIxNiA0IDExLjM5ODcgNCAxMS45NjA4QzQgMTIuNTM1OSAzLjgwMTI4IDEzLjAxOTYgMy40MDM4NSAxMy40MTE4WiIgZmlsbD0iI0Q3QTk0OCIvPgo8L3N2Zz4K"
        />
        {text}
      </div>
      <div onClick={close} className={styles.closeButton}>
        <svg
          viewBox="0 0 40 40"
          enable-background="new 0 0 40 40"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="7"
            y1="7"
            x2="33"
            y2="33"
            stroke="#fff"
            stroke-width="5"
            stroke-linecap="round"
            stroke-miterlimit="10"
          ></line>
          <line
            x1="33"
            y1="7"
            x2="7"
            y2="33"
            stroke="#fff"
            stroke-width="5"
            stroke-linecap="round"
            stroke-miterlimit="10"
          ></line>
        </svg>
      </div>
    </div>
  );
};

export default Error;
