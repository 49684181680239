import { h } from "preact";
import styles from "./CompleteOrderButton.module.css";
import Button from "../Button";
import { useState } from "preact/hooks";
import RedirectModal from "../RedirectModal";
import { changeNumberForm } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { selectContact, selectLeadId } from "../../store/leadReducer";
import { useUpdateLeadStatus, useContact, useCheckBasket } from "../utils";
import { useRedirect } from "../URLManager";
import { setShowBasket } from "../../store/uiReducer";
const { LEAD_STATUS } = require("../../../../back/src/common/lead");

const CompleteOrderButton = ({ primary = null, className = "" }) => {
  const dispatch = useDispatch();
  const { validateContact } = useContact();
  const checkBasket = useCheckBasket();
  const leadId = useSelector(selectLeadId);
  const contact = useSelector(selectContact);
  const updateLeadStatus = useUpdateLeadStatus();
  const { goToShipping } = useRedirect();

  const [showRedirectModal, setRedirectModal] = useState(false);
  const [showWaiting, setWaiting] = useState(false);
  const onSubmit = () => {
    if (checkBasket() && validateContact(contact)) {
      updateLeadStatus(LEAD_STATUS.SHIPPING)
        .then(() => {
          setWaiting(false);
          setRedirectModal(true);
        })
        .catch(() => {
          setWaiting(false);
        });
      setWaiting(true);
    } else {
      dispatch(setShowBasket(false));
    }
  };

  return (
    <div className={[styles.container, className].join(" ")}>
      <Button
        className={styles.button}
        onClick={onSubmit}
        disabled={showWaiting}
        primary={primary}
        isLoading={showWaiting}
      >
        Оформить заказ
      </Button>
      {showRedirectModal && (
        <RedirectModal
          visible={showRedirectModal}
          close={() => setRedirectModal(false)}
          goToNextPage={() => goToShipping(leadId)}
        >
          {(count) => (
            <span>
              Через {count}{" "}
              {changeNumberForm(count, {
                one: "секунду",
                two: "секунды",
                five: "секунд",
              })}{" "}
              Вы будете автоматически перенаправлены на страницу заполнения
              данных по доставке
            </span>
          )}
        </RedirectModal>
      )}
    </div>
  );
};

export default CompleteOrderButton;
